import { useState } from 'react';

import { FiltersContainer, HomeContainer, HomeContentContainer } from 'components/containers/Containers';
import { useResponsive } from 'hooks/useResponsive';
import { useCampaignCategories } from 'providers/cache/cachedDataHooks';

import { CampaignsFeatured } from './components/CampaignsFeatured';
import { CampaignsList } from './components/CampaignsList';
import { CampaignsFilters } from './components/filters/CampaignsFilters';
import { MobileCampaignsFilters } from './components/filters/MobileCampaignsFilters';

export const Campaigns = () => {
  const { campaignCategories } = useCampaignCategories();
  const { isTablet } = useResponsive();

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const toggleFilters = () => setIsFiltersOpen(!isFiltersOpen);

  if (isFiltersOpen) {
    return <MobileCampaignsFilters toggleFilters={toggleFilters} />;
  }
  return (
    <HomeContainer data-testid={'campaigns-page'}>
      {!isTablet && (
        <FiltersContainer data-testid={'campaigns-filters'}>
          <CampaignsFilters categories={campaignCategories || []} />
        </FiltersContainer>
      )}
      <HomeContentContainer>
        <CampaignsFeatured />
        <CampaignsList toggleFilters={toggleFilters} />
      </HomeContentContainer>
    </HomeContainer>
  );
};
