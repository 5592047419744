import { LocationDetailsDto } from 'services/utils/location/locationService.dto';

import { ItemWithAuctionDto } from './withAuctionsData';

export const ITEM_STATUSES = ['PUBLISHED', 'SOLD', 'ARCHIVED', 'BLOCKED', 'UNSOLD'] as const;
export const ITEM_TYPES = ['REGULAR', 'SERVICE'] as const;
export const SELLING_METHODS = ['STANDARD', 'AUCTION', 'MULTIPLE'] as const;

export type ItemStatus = typeof ITEM_STATUSES[number];
export type ItemType = typeof ITEM_TYPES[number];
export type ItemSellingMethod = typeof SELLING_METHODS[number];
export type AuctionStatus = 'ONGOING' | 'AWAITING_PAYMENT' | 'CLOSED' | 'CANCELED' | 'COMPLETED' | 'DELETED';
export type OfferStatus = 'NEW' | 'ACCEPTED' | 'REJECTED' | 'FINISHED' | 'CANCELED';

export interface ItemDto {
  id: number;
  imageUrlList: string[];
  videoUrl?: string;
  unsafeImageUrls?: string[];
  price?: number;
  currency?: string;
  title: string;
  category: string;
  description?: string;
  canNationwideShipping: boolean;
  canAcceptLoHiOffers: boolean;
  canLocalPickUpDelivery: boolean;
  weightUpTo?: number;
  unitOfMeasure?: string;
  supportedCampaignId: number;
  ownerId: number;
  itemLocation: ItemLocationDto;
  descriptionSafe?: boolean;
  titleSafe?: boolean;
  created?: string;
  itemStatus: ItemStatus;
  condition: string;
  hidden: boolean;
  itemType: ItemType;
  sellingMethod: ItemSellingMethod;
  unitsQuantity: number;
  unitsSold: number;
  defaultShipFromAddressId?: number;
}

export interface ItemLocationDto {
  state: string;
  zipCode: string;
  city: string;
  countryCode: string;
}

export interface AuctionDto {
  id: number;
  itemId: number;
  startDateTime: string;
  endDateTime: string;
  status: AuctionStatus;
  minimumPrice?: number;
  currency: string;
  winningBidId?: number;
  bids: BidDto[];
}

export interface BidDto {
  id: number;
  userId: number;
  amount: number;
  created: string;
}

export interface ItemConditionDto {
  code: string;
  displayName: string;
  shortDisplayName: string;
}

export interface NewOfferDto {
  amount: number;
  itemId: number;
}

export interface OfferDto {
  id: number;
  amount: number;
  offererId: number;
  itemId: number;
  status: OfferStatus;
}

export interface MyActivitySellingStatsDto {
  sellingCount: number;
}

export interface MyActivityBoughtSoldStatsDto {
  boughtCount: number;
  soldCount: number;
}

export interface ItemStatsDto {
  boughtCount: number;
  sellingCount: number;
  soldCount: number;
}

export interface TrackedItemDto extends ItemWithAuctionDto {
  orderId?: number;
}

export interface ItemCategoryDto {
  displayName: string;
  code: string;
  imageUrl: string;
  categoryOrder: number;
  itemType: ItemType;
}

export interface PriceRangeDto {
  firstQuartile: number;
  itemFrequencies: number[];
  priceRangeLimits: number[];
  thirdQuartile: number;
}

export interface NewItemDto {
  canAcceptLoHiOffers: boolean;
  canLocalPickUpDelivery: boolean;
  canNationwideShipping: boolean;
  category: string;
  condition?: string;
  description?: string;
  imageUrlList: string[];
  videoUrl?: string | null;
  itemLocation: LocationDetailsDto;
  price?: number;
  supportedCampaignId: number;
  title: string;
  weightUpTo?: number;
  itemType: ItemType;
  sellingMethod?: ItemSellingMethod;
  unitsQuantity?: number;
  auction?: {
    endDateTime?: string;
    minimumPrice?: number;
  };
}

export interface EditItemDto extends NewItemDto {
  id: number;
  defaultShipFromAddressId?: number;
}

export interface EditAuctionDto {
  minimumPrice?: number;
  endDateTime?: string;
}
