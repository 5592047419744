import { UIEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Text1 } from 'components/typography/Texts';

import { itemWeights } from './itemWeight.types';
import { WeightButton } from './WeightButton';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 12px;
`;

const ButtonsContainer = styled.div<{ $gradient: string }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  mask-image: linear-gradient(${props => props.$gradient});
  padding-bottom: 5px;
`;

interface Props {
  selectedWeightUpTo?: number;
  onWeightSelected: (weightUpTo: number) => void;
}

const gradientRight = 'to right, black 0%, black 90%, rgba(0, 0, 0, 0) 100%';
const gradientLeft = 'to left, black 0%, black 90%, rgba(0, 0, 0, 0) 100%';
const gradientBoth = 'to right, rgba(0, 0, 0, 0) 0%, black 10%, black 90%, rgba(0, 0, 0, 0) 100%';

export const ItemWeightSelector = ({ selectedWeightUpTo, onWeightSelected }: Props) => {
  const { t } = useTranslation('addItem');
  const [gradient, setGradient] = useState<string>(gradientRight);

  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    const { scrollLeft, scrollWidth, clientWidth } = event.currentTarget;
    if (scrollLeft === 0) setGradient(gradientRight);
    else if (scrollLeft + clientWidth >= scrollWidth) setGradient(gradientLeft);
    else setGradient(gradientBoth);
  };

  return (
    <Container>
      <Text1>{t('delivery.estimate-weight')}</Text1>
      <ButtonsContainer data-testid={'items-weight-list'} onScroll={handleScroll} $gradient={gradient}>
        {itemWeights.map(itemWeight => {
          const { weightUpTo, label, Icon } = itemWeight;
          const isSelected = weightUpTo === selectedWeightUpTo;
          return (
            <WeightButton
              key={weightUpTo}
              label={label}
              isSelected={isSelected}
              onClick={() => onWeightSelected(weightUpTo)}>
              <Icon />
            </WeightButton>
          );
        })}
      </ButtonsContainer>
    </Container>
  );
};
