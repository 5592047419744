import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { Text1 } from 'components/typography/Texts';
import { LoadingIndicator } from 'components/ui-elements/LoadingIndicator';
import { CheckoutStatus } from 'features/checkout/item/checkoutStatus';
import { CheckoutResult } from 'features/checkout/payment/checkoutResult.types';
import ApplePayButton from 'features/checkout/social-media/apple/ApplePayButton';
import GooglePayButton from 'features/checkout/social-media/google/GooglePayButton';
import { PaymentButtonState, PaymentTokenData } from 'features/checkout/social-media/socialMedia.types';
import { usePaymentConfig } from 'providers/cache/cachedDataHooks';
import { PurchaseDto } from 'services/order/orderService.dto';
import { PaymentMethodType } from 'services/payment/paymentService.dto';

import ButtonSeparator from './ButtonSeparator';
import CreditCardButton from './CreditCardButton';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  gap: 10px;
`;

const NoPaymentMethodText = styled(Text1)`
  color: ${theme.color.red};
`;

interface Props {
  purchase?: PurchaseDto;
  checkoutCardStatus: CheckoutStatus;
  checkoutTokenStatus: CheckoutStatus;
  confirmCheckoutWithCard: () => void;
  confirmCheckoutWithToken: (data: PaymentTokenData) => Promise<CheckoutResult>;
}

const PaymentButtons = (props: Props) => {
  const { purchase, checkoutCardStatus, checkoutTokenStatus } = props;
  const { confirmCheckoutWithCard, confirmCheckoutWithToken } = props;

  const { t } = useTranslation('checkout');
  const { paymentConfig } = usePaymentConfig();

  const [applePayState, setApplePayState] = useState<PaymentButtonState>('notInitialized');
  const [googlePayState, setGooglePayState] = useState<PaymentButtonState>('notInitialized');

  if (!paymentConfig)
    return (
      <Container>
        <LoadingIndicator />
      </Container>
    );

  const { applePayPaymentId, googlePayPaymentId } = paymentConfig;

  const isPaymentMethodEnabled = (type: PaymentMethodType) =>
    paymentConfig.availablePaymentMethodTypes.includes(type) ?? false;

  const displayCreditCard = isPaymentMethodEnabled('creditCard');
  const displayApplePay = isPaymentMethodEnabled('applePay') && checkoutTokenStatus === 'Ready' && !!applePayPaymentId;
  const displayGooglePay =
    isPaymentMethodEnabled('googlePay') && checkoutTokenStatus === 'Ready' && !!googlePayPaymentId;

  const hasAnyPaymentMethod =
    isPaymentMethodEnabled('creditCard') ||
    (isPaymentMethodEnabled('applePay') && applePayState !== 'notAvailable') ||
    (isPaymentMethodEnabled('googlePay') && googlePayState !== 'notAvailable');

  const displaySeparator = displayCreditCard && (displayGooglePay || displayApplePay);
  const displayNoPaymentMethod = !!paymentConfig && !hasAnyPaymentMethod;

  return (
    <Container>
      {displayCreditCard && <CreditCardButton checkoutStatus={checkoutCardStatus} onClick={confirmCheckoutWithCard} />}

      {displaySeparator && <ButtonSeparator />}

      {displayGooglePay && (
        <GooglePayButton
          amount={purchase!.amountTotal}
          currency={purchase!.currency}
          paymentId={googlePayPaymentId}
          onStateChange={setGooglePayState}
          onPaymentAuthorized={confirmCheckoutWithToken}
        />
      )}

      {displayApplePay && (
        <ApplePayButton
          amount={purchase!.amountTotal}
          currency={purchase!.currency}
          paymentId={applePayPaymentId}
          onStateChange={setApplePayState}
          onPaymentAuthorized={confirmCheckoutWithToken}
        />
      )}

      {displayNoPaymentMethod && (
        <NoPaymentMethodText data-testid={'no-payment-method'}>{t('summary.no-payment-method')}</NoPaymentMethodText>
      )}
    </Container>
  );
};

export default PaymentButtons;
