import { ReactElement } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ContentContainer } from 'components/containers/ItemCampaignContainers';
import ObjectError from 'components/ui-elements/ObjectError';
import { ObjectNotFound } from 'components/ui-elements/ObjectNotFound';
import Spinner from 'components/ui-elements/Spinner';
import { useFraudSession } from 'features/checkout/payment/useFraudSession';
import { CheckoutSuccessState } from 'features/checkout/success/checkoutSuccessState';
import { useAuth } from 'providers/AuthProvider';
import { useCurrentUser, usePaymentConfig } from 'providers/cache/cachedDataHooks';
import { PaymentProvider } from 'providers/PaymentProvider';
import { ShippingAddressProvider } from 'providers/ShippingAddressProvider';
import { useItemData } from 'services/item/useItemData';
import { PurchaseDto } from 'services/order/orderService.dto';
import { TransactionDto } from 'services/payment/paymentService.dto';

import ItemCheckoutContent from './ItemCheckoutContent';
import { ItemCheckoutState } from './itemCheckoutState';

export const ItemCheckout = () => {
  const { itemId } = useParams();
  const { logged } = useAuth();
  const { currentUser } = useCurrentUser();
  const { paymentConfig } = usePaymentConfig();
  const navigate = useNavigate();

  const { loading, content: item, error } = useItemData(Number(itemId));
  const { state }: { state: ItemCheckoutState } = useLocation();
  useFraudSession();

  const renderWithContainer = (content: ReactElement) => <ContentContainer>{content}</ContentContainer>;

  if (!logged) return renderWithContainer(<ObjectNotFound testId={'not-logged-error'} />);

  if (loading || !currentUser || !paymentConfig) return renderWithContainer(<Spinner displayLabel={true} />);

  if (error?.response?.status === 410) return renderWithContainer(<ObjectNotFound testId={'hidden-error'} />);

  if (error || !item) return renderWithContainer(<ObjectError error={error} testId={'loading-error'} />);

  if (item.sellingMethod !== 'AUCTION' && item.itemStatus !== 'PUBLISHED')
    return renderWithContainer(<ObjectNotFound testId={'not-published-error'} />);

  if (item.sellingMethod === 'AUCTION' && state?.auctionOrder?.status !== 'CONFIRMED')
    return renderWithContainer(<ObjectNotFound testId={'auction-error'} />);

  if (currentUser.id === item.ownerId) return renderWithContainer(<ObjectNotFound testId={'owner-error'} />);

  const onPurchasePaid = (purchase: PurchaseDto, transaction: TransactionDto) => {
    const state: CheckoutSuccessState = { purchase, transaction };
    navigate('/checkout/success', { state });
  };

  return renderWithContainer(
    <PaymentProvider>
      <ShippingAddressProvider>
        <ItemCheckoutContent
          item={item}
          payForOffer={state?.payForOffer ?? false}
          existingOrder={state?.auctionOrder}
          onPurchasePaid={onPurchasePaid}
        />
      </ShippingAddressProvider>
    </PaymentProvider>
  );
};
