import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { Text1Bold } from 'components/typography/Texts';
import { LoadingIndicator } from 'components/ui-elements/LoadingIndicator';
import { useCurrentUser } from 'providers/cache/cachedDataHooks';
import { useConversations } from 'providers/ConversationProvider';
import { useMessages } from 'providers/MessageProvider';
import { ConversationDto, InternalMessageDto, MessageDto } from 'services/message/messageService.dto';
import { parseBackendDate } from 'utils/backendDateParser';

import { MessageBubble } from './MessageBubble';
import { MessageInput } from './MessageInput';
import { MessageThreadHeader } from './MessageThreadHeader';

const BOTTOM_OFFSET = 120;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-left: 1px solid ${theme.color.darkGray};
  border-right: 1px solid ${theme.color.darkGray};
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 10px 60px;
  overflow-y: auto;
  height: 100%;

  ${theme.mq.desktop} {
    padding: 24px;
  }

  ${theme.mq.phone} {
    padding: 12px;
  }
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  width: 20px;
  height: 20px;
  align-self: center;
  margin-bottom: 8px;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border-left: 1px solid ${theme.color.darkGray};
  border-right: 1px solid ${theme.color.darkGray};
`;

interface Props {
  conversation: ConversationDto;
  messages: InternalMessageDto[];
}

export const MessageChat = ({ conversation, messages }: Props) => {
  const { t } = useTranslation('message');
  const { currentUser } = useCurrentUser();
  const [isMessageTop, setIsMessageTop] = useState(false);
  const [isMessageBottom, setIsMessageBottom] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);
  const { fetchConversationMessages } = useConversations();
  const { isLoading } = useMessages();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isMessageTop) {
      fetchConversationMessages(conversation.conversationId);
    }
  }, [isMessageTop]);

  useEffect(() => {
    if (!!conversation?.conversationId) setIsLoaded(false);
  }, [conversation?.conversationId]);

  useEffect(() => {
    if (messages.length > 0 && !isLoaded) {
      if (containerRef.current) containerRef.current.scrollTo(0, containerRef.current.scrollHeight);
      setIsLoaded(true);
    }
  }, [messages.length, isLoaded]);

  useEffect(() => {
    if (messages.length > 0 && isMessageBottom) {
      if (containerRef.current) {
        containerRef.current.scrollTo({
          top: containerRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }
    }
  }, [messages]);

  if (!conversation)
    return (
      <TextContainer>
        <Text1Bold>{t('messenger.select-chat')}</Text1Bold>
      </TextContainer>
    );

  const isFirstMsgInDay = (msg: MessageDto, prevMsg: MessageDto | undefined) => {
    if (!prevMsg) return true;
    const prevMsgDate = parseBackendDate(prevMsg.createdAt);
    const msgDate = parseBackendDate(msg.createdAt);
    return !dayjs(msgDate).isSame(prevMsgDate, 'day');
  };

  const renderMessages = () => {
    return messages.map((message, index, elements) => {
      const prevMsg = index === 0 ? undefined : elements[index - 1];
      const firstMessageInDay = isFirstMsgInDay(message, prevMsg);
      const isMessageOwner = currentUser?.id === message.authorId;

      return (
        <MessageBubble
          key={`${message.type}_${message.internalId}`}
          message={message}
          isMessageOwner={isMessageOwner}
          isFirstMsgInDay={firstMessageInDay}
        />
      );
    });
  };

  const handleScroll = (e: any) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    const isTop = scrollTop === 0;
    const isBottom = scrollTop >= scrollHeight - clientHeight - BOTTOM_OFFSET;

    setIsMessageTop(isTop);
    setIsMessageBottom(isBottom);
  };

  return (
    <Container>
      <MessageThreadHeader conversation={conversation} />
      <MessageContainer onScroll={handleScroll} ref={containerRef}>
        {isMessageTop && isLoading(conversation.conversationId) && <StyledLoadingIndicator />}
        {renderMessages()}
      </MessageContainer>
      <MessageInput conversation={conversation} />
    </Container>
  );
};
