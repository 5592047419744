import { createContext, PropsWithChildren, useContext } from 'react';

import { CampaignCategoryDto } from 'services/campaign/campaignService.dto';
import { ItemCategoryDto, ItemConditionDto, PriceRangeDto } from 'services/item/itemService.dto';
import { PaymentConfigDto } from 'services/payment/paymentService.dto';
import { ReasonDto } from 'services/report/reportService.dto';
import { CurrentUserDto } from 'services/user/userService.dto';

import { CacheState } from './cachedDataTypes';
import { useCacheState } from './useCacheState';

export interface CacheContextType {
  campaignCategories: CacheState<CampaignCategoryDto[]>;
  currentUser: CacheState<CurrentUserDto>;
  itemCategories: CacheState<ItemCategoryDto[]>;
  itemConditions: CacheState<ItemConditionDto[]>;
  paymentConfig: CacheState<PaymentConfigDto>;
  priceRange: CacheState<PriceRangeDto>;
  reportReasons: CacheState<ReasonDto[]>;
}

const CacheContext = createContext<CacheContextType>(null!);

function CacheProvider({ children }: PropsWithChildren) {
  const campaignCategories = useCacheState<CampaignCategoryDto[]>();
  const currentUser = useCacheState<CurrentUserDto>();
  const itemCategories = useCacheState<ItemCategoryDto[]>();
  const itemConditions = useCacheState<ItemConditionDto[]>();
  const paymentConfig = useCacheState<PaymentConfigDto>();
  const priceRange = useCacheState<PriceRangeDto>();
  const reportReasons = useCacheState<ReasonDto[]>();

  return (
    <CacheContext.Provider
      value={{
        campaignCategories,
        currentUser,
        itemCategories,
        itemConditions,
        paymentConfig,
        priceRange,
        reportReasons,
      }}>
      {children}
    </CacheContext.Provider>
  );
}

const useCache = () => useContext(CacheContext);

export { CacheProvider, useCache };
