import { DeliveryType } from 'features/checkout/delivery/deliveryType';
import { ItemDto } from 'services/item/itemService.dto';
import { PurchaseDto } from 'services/order/orderService.dto';
import { PaymentMethodDto } from 'services/payment/paymentService.dto';
import { UserShippingAddressDto } from 'services/user/userService.dto';

import { CheckoutStatus } from './checkoutStatus';

export const deriveDefaultDeliveryType = (item: ItemDto, existingOrder?: PurchaseDto): DeliveryType => {
  if (existingOrder) {
    return existingOrder.shipmentRequired ? 'Shipment' : 'PickUp';
  } else {
    return item.canLocalPickUpDelivery ? 'PickUp' : 'Shipment';
  }
};

export const deriveIsDeliveryTypeLocked = (item: ItemDto): boolean =>
  !item.canNationwideShipping || !item.canLocalPickUpDelivery;

interface CheckoutStatusData {
  isTokenPayment: boolean;
  isLoadingData: boolean;
  deliveryType: DeliveryType;
  paymentMethod?: PaymentMethodDto;
  shippingAddress?: UserShippingAddressDto;
  purchase?: PurchaseDto;
}

export const deriveCheckoutStatus = (data: CheckoutStatusData): CheckoutStatus => {
  const { isLoadingData, isTokenPayment } = data;
  const { deliveryType, purchase, paymentMethod, shippingAddress } = data;

  if (isLoadingData) {
    return 'NotReady';
  } else if (!shippingAddress && deliveryType === 'Shipment') {
    return 'MissingAddress';
  } else if (!paymentMethod && !isTokenPayment) {
    return 'MissingPayment';
  } else if (!purchase) {
    return 'NotReady';
  } else {
    return 'Ready';
  }
};
