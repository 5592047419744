import { usePopup } from 'hooks/usePopup';

import LoginWithEmailPopup from './LoginWithEmailPopup';

export const useLoginWithEmailPopup = (onLogged?: () => void) => {
  const { showPopup, hidePopup } = usePopup();

  const handleLogged = () => {
    onLogged && onLogged();
    hidePopup();
  };

  const showLoginWithEmailPopup = () => showPopup(<LoginWithEmailPopup onLogged={handleLogged} />);

  return { showLoginWithEmailPopup };
};
