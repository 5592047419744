import styled from 'styled-components';

import { MultiStepFormProvider } from 'components/form/MultiStepFormProvider';
import { AddItemContextProps, AddItemProvider, useAddItem } from 'features/add-item/context/AddItemProvider';
import SetPrice from 'features/add-item/form/steps/SetPrice';

import AddPhotos from './steps/AddPhotos';
import Confirm from './steps/Confirm';
import SelectCampaign from './steps/SelectCampaign';
import SelectCondition from './steps/SelectCondition';
import SelectSellingMethod from './steps/SelectSellingMethod';
import SelectType from './steps/SelectType';
import SetAuctionEnd from './steps/SetAuctionEnd';
import SetItemDelivery from './steps/SetItemDelivery';
import SetMultipleItemQuantity from './steps/SetMultipleItemQuantity';
import SetServiceLocation from './steps/SetServiceLocation';

const Container = styled.div`
  width: 100%;
  display: flex;
`;

const AddItemForm = () => {
  const { formData } = useAddItem();
  const isService = formData.type === 'SERVICE';
  const isAuction = formData.sellingMethod === 'AUCTION';
  const isMultiple = formData.sellingMethod === 'MULTIPLE';

  return (
    <Container data-testid={'add-item-form'}>
      <MultiStepFormProvider>
        <SelectType />
        <AddPhotos />
        <SelectSellingMethod />
        {!isService && <SelectCondition />}
        <SetPrice />
        {isAuction && <SetAuctionEnd />}
        {isMultiple && <SetMultipleItemQuantity />}
        {isService ? <SetServiceLocation /> : <SetItemDelivery />}
        <SelectCampaign />
        <Confirm />
      </MultiStepFormProvider>
    </Container>
  );
};

const AddItemFormWithContext = ({ supportedCampaign }: AddItemContextProps) => {
  return (
    <AddItemProvider supportedCampaign={supportedCampaign}>
      <AddItemForm />
    </AddItemProvider>
  );
};

export default AddItemFormWithContext;
