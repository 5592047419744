import { range } from 'lodash';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';

import { useMultiStepForm } from './MultiStepFormProvider';

const Container = styled.div`
  width: 100%;
  margin: 24px 0;
  padding: 0 40px;
  display: flex;
  flex-direction: row;
  gap: 6px;

  ${theme.mq.phone} {
    padding: 0 10px;
  }
`;

const Line = styled.div<{ $isActive: boolean }>`
  height: 6px;
  border-radius: 10px;
  width: 100%;
  background-color: ${props => (props.$isActive ? theme.color.red : theme.color.gray)};
`;

const FormProgressIndicator = () => {
  const { activeStepIndex, totalStepsCount } = useMultiStepForm();

  return (
    <Container>
      {range(totalStepsCount).map(index => (
        <Line data-testid={'form-progress-line'} key={index} $isActive={index <= activeStepIndex} />
      ))}
    </Container>
  );
};

export default FormProgressIndicator;
