import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { BaseInputProps } from 'components/inputs/BaseInputField';
import TextInputField from 'components/inputs/TextInputField';
import { Text2 } from 'components/typography/Texts';
import Spinner from 'components/ui-elements/Spinner';
import { shipmentService } from 'services/shipment/shipmentService';
import { GeocodingDto } from 'services/shipment/shipmentService.dto';
import { LocationDetailsDto } from 'services/utils/location/locationService.dto';

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
`;

const StyledTextInput = styled(TextInputField)`
  flex: 1;
`;

const LocationInfo = styled.div`
  display: flex;
  padding: 5px 10px;
  background-color: ${theme.color.white};
  flex: 0.6;
  text-align: start;
`;

interface Props extends Omit<BaseInputProps, 'value' | 'onChange'> {
  value?: LocationDetailsDto;
  onChange: (value?: LocationDetailsDto) => void;
  validZipLength?: number;
}

const parseToLocation = (geocoding: GeocodingDto): LocationDetailsDto => {
  const { zipCode, city, state, countryCode, coordinates } = geocoding;
  const { latitude, longitude } = coordinates;
  return {
    zipCode,
    city,
    state: state.code,
    countryCode,
    latitude,
    longitude,
    locationSource: 'ZIP_CODE',
  };
};

const LocationByZipPicker = (props: Props) => {
  const { value, onChange, validZipLength, ...otherProps } = props;
  const [zipCode, setZipCode] = useState(value?.zipCode);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (inputValue: string) => {
    const validInput = inputValue.replace(/[^0-9-]/g, '');
    setZipCode(validInput);
  };

  useEffect(() => {
    if (zipCode && zipCode === value?.zipCode) return;
    if (zipCode && zipCode.length === validZipLength) {
      setIsLoading(true);
      shipmentService
        .fetchGeocoding(zipCode)
        .then(geocoding => onChange(parseToLocation(geocoding.data)))
        .catch(() => onChange(undefined))
        .finally(() => setIsLoading(false));
    } else {
      onChange(undefined);
    }
  }, [zipCode]);

  const locationInfo = value && `${value.city}, ${value.state}`;
  return (
    <Container>
      <StyledTextInput value={zipCode || ''} onChange={handleChange} maxLength={validZipLength} {...otherProps} />
      <LocationInfo>
        {isLoading && <Spinner />}
        <Text2 data-testid={'location-info'}>{locationInfo}</Text2>
      </LocationInfo>
    </Container>
  );
};

export default LocationByZipPicker;
