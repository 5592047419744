import { createContext, PropsWithChildren, useContext, useState } from 'react';
import { FormikErrors } from 'formik';

import { useItemError } from 'features/add-item/form/error/useItemError';
import { useSharePopup } from 'features/share/useSharePopup';
import { usePaymentConfig } from 'providers/cache/cachedDataHooks';
import { CampaignDto } from 'services/campaign/campaignService.dto';
import { itemService } from 'services/item/itemService';
import { EditItemDto, NewItemDto } from 'services/item/itemService.dto';
import { ItemWithAuctionDto } from 'services/item/withAuctionsData';
import { parseBackendDate } from 'utils/backendDateParser';

import { ItemFormData, useItemForm } from './useItemForm';

export const MAX_PHOTOS_COUNT = 12;
export const MAX_PHOTO_SIZE = 15 * 1024 * 1024;
export const MIN_UNITS_QUANTITY = 2;
export const MAX_UNITS_QUANTITY = 999999;

interface AddItemContextType {
  save: () => Promise<void>;
  isLoading: boolean;
  isSaving: boolean;
  addPhoto: (file: File) => void;
  deletePhoto: (id: string) => void;
  movePhoto: (from: number, to: number) => void;
  maxPhotosCount?: number;
  formData: ItemFormData;
  setFieldValue: (field: string, value: any) => void;
  errors: FormikErrors<ItemFormData>;
  isEditMode: boolean;
  isFormValid: boolean;
  isUnitSold: boolean;
  hasAuctionBids: boolean;
}

const AddItemContext = createContext<AddItemContextType>(null!);

export interface AddItemContextProps extends PropsWithChildren {
  item?: ItemWithAuctionDto;
  supportedCampaign?: CampaignDto;
  onItemSaved?: () => void;
}

const AddItemProvider = ({ item, onItemSaved, supportedCampaign, children }: AddItemContextProps) => {
  const { paymentConfig } = usePaymentConfig();
  const { showItemCreatedSharePopup } = useSharePopup();
  const { showAddItemErrorPopup } = useItemError();

  const isLoading = !!item && !supportedCampaign && !paymentConfig;
  const [isSaving, setIsSaving] = useState(false);

  const postItem = async (request: NewItemDto) => {
    setIsSaving(true);
    try {
      const createdItem = await itemService.addItem(request);
      showItemCreatedSharePopup(createdItem.data, itemForm.values.supportedCampaign);
      onItemSaved && onItemSaved();
    } catch (error) {
      showAddItemErrorPopup();
    } finally {
      setIsSaving(false);
    }
  };

  const updateItem = async (request: EditItemDto) => {
    setIsSaving(true);
    try {
      await itemService.editItem(request);
      if (isAuctionEdited(request)) await itemService.editAuction(item!.auction!.id!, request.auction!);
      onItemSaved && onItemSaved();
    } catch (error) {
      showAddItemErrorPopup();
    } finally {
      setIsSaving(false);
    }
  };

  const itemForm = useItemForm(paymentConfig?.maxItemPrice || 0, postItem, updateItem, item, supportedCampaign);

  const isAuctionEdited = (request: EditItemDto) => {
    if (item && item.auction && item.sellingMethod === 'AUCTION' && request.auction) {
      const initialEndDate = parseBackendDate(item.auction.endDateTime).getTime();
      const newEndDate = new Date(request.auction.endDateTime!).getTime();
      return request.auction.minimumPrice !== item.auction.minimumPrice || newEndDate !== initialEndDate;
    }
    return false;
  };

  const isUnitSold = !!item && item.unitsSold > 0;
  const hasAuctionBids = !!item && !!item.auction && item.auction.bids.length > 0;
  const isEditMode = !!item;

  return (
    <AddItemContext.Provider
      value={{
        save: itemForm.submitForm,
        isLoading,
        isSaving,
        formData: itemForm.values,
        maxPhotosCount: MAX_PHOTOS_COUNT,
        isFormValid: itemForm.isValid && itemForm.dirty && !itemForm.isValidating,
        isEditMode,
        isUnitSold,
        hasAuctionBids,
        ...itemForm,
      }}>
      {children}
    </AddItemContext.Provider>
  );
};

const useAddItem = () => useContext(AddItemContext);

export { AddItemProvider, useAddItem };
