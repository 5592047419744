import { AxiosPromise } from 'axios';

import PageableType from 'services/pageableType';
import { apiService, apiUrl } from 'services/utils/apiService';

import {
  AuctionDto,
  BidDto,
  EditAuctionDto,
  EditItemDto,
  ItemCategoryDto,
  ItemConditionDto,
  ItemDto,
  MyActivitySellingStatsDto,
  NewItemDto,
  NewOfferDto,
  OfferDto,
  PriceRangeDto,
} from './itemService.dto';

class ItemService {
  static BASE_URL = `${apiUrl}/api/item`;

  fetchItemDetails(itemId: number): AxiosPromise<ItemDto> {
    const url = `${ItemService.BASE_URL}/public/items/${itemId}`;
    return apiService.get(url);
  }

  fetchBulkItemsDetails(itemsIds: number[]): AxiosPromise<ItemDto[]> {
    const url = `${ItemService.BASE_URL}/public/items/bulk`;
    return apiService.get(url, { ids: itemsIds.join() });
  }

  fetchBulkAuctions(itemsIds: number[]): AxiosPromise<AuctionDto[]> {
    const url = `${ItemService.BASE_URL}/public/auctions/bulk`;
    return apiService.get(url, { itemIds: itemsIds.join() });
  }

  fetchAuction(itemId: number): AxiosPromise<AuctionDto> {
    const url = `${ItemService.BASE_URL}/public/auctions`;
    return apiService.get(url, { itemId });
  }

  fetchSimilarItems(itemId: number) {
    const url = `${ItemService.BASE_URL}/public/similar-items/${itemId}`;
    return apiService.get(url);
  }

  fetchConditions(): AxiosPromise<ItemConditionDto[]> {
    const url = `${ItemService.BASE_URL}/public/conditions`;
    return apiService.get(url, {}, {});
  }

  addBid(auctionId: number, amount: number): AxiosPromise<BidDto> {
    const url = `${ItemService.BASE_URL}/auctions/${auctionId}/bid`;
    return apiService.post(url, { amount });
  }

  registerOffer(newOfferDto: NewOfferDto): AxiosPromise<OfferDto> {
    const url = `${ItemService.BASE_URL}/offers`;
    return apiService.post(url, newOfferDto);
  }

  cancelOffer(offerId: number) {
    const url = `${ItemService.BASE_URL}/offers/${offerId}`;
    return apiService.patch(url, { status: 'CANCELED' });
  }

  acceptOffer(offerId: number) {
    const url = `${ItemService.BASE_URL}/offers/${offerId}`;
    return apiService.put(url);
  }

  rejectOffer(offerId: number) {
    const url = `${ItemService.BASE_URL}/offers/${offerId}`;
    return apiService.delete(url);
  }

  fetchMyItemsStats(): AxiosPromise<MyActivitySellingStatsDto> {
    const url = `${ItemService.BASE_URL}/user/items/stats`;
    return apiService.get(url, {}, {});
  }

  fetchMySelling(page: number, size: number, sort?: string): AxiosPromise<PageableType<ItemDto>> {
    const url = `${ItemService.BASE_URL}/user/items/selling`;
    return apiService.get(url, { page: page, size: size, sort: sort ? sort : 'created,desc' });
  }

  fetchMyLastOffer(itemId: number): AxiosPromise<OfferDto> {
    const url = `${ItemService.BASE_URL}/items/${itemId}/my-last-offer`;
    return apiService.get(url);
  }

  fetchCategories(): AxiosPromise<ItemCategoryDto[]> {
    const url = `${ItemService.BASE_URL}/public/categories`;
    return apiService.get(url);
  }

  fetchPriceRange(): AxiosPromise<PriceRangeDto> {
    const url = `${ItemService.BASE_URL}/public/price-range`;
    return apiService.get(url);
  }

  addItem(itemDetails: NewItemDto): AxiosPromise<ItemDto> {
    const url = `${ItemService.BASE_URL}/items`;
    return apiService.post(url, itemDetails);
  }

  editItem(itemDetails: EditItemDto): AxiosPromise<ItemDto> {
    const url = `${ItemService.BASE_URL}/items`;
    return apiService.put(url, itemDetails);
  }

  editAuction(auctionId: number, auctionDetails: EditAuctionDto): AxiosPromise<AuctionDto> {
    const url = `${ItemService.BASE_URL}/auctions/${auctionId}`;
    return apiService.patch(url, auctionDetails);
  }

  removeItem(itemId: number) {
    const url = `${ItemService.BASE_URL}/items/${itemId}`;
    return apiService.delete(url);
  }
}

const itemService = new ItemService();

export { itemService, ItemService };
