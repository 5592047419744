import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { parseBackendDate } from './backendDateParser';

import 'dayjs/locale/en';

dayjs.locale('en');
dayjs.extend(utc);
dayjs.extend(timezone);

const DATE_FORMAT_SHORT = 'MMM D';
const DATE_FORMAT_LONG = 'MMM D, YYYY';

const FORMAT_DATE_TIME_SHORT = 'MMM D h:mm a';
const FORMAT_DATE_TIME_LONG = 'MMM D, YYYY h:mm a';

const FORMAT_TIME = 'h:mm a';

const formatDateShort = (date: Date) => dayjs(date).format(DATE_FORMAT_SHORT);

export const formatDateLong = (date: Date) => dayjs(date).format(DATE_FORMAT_LONG);

export const formatDate = (date: Date) => {
  if (dayjs().year() === dayjs(date).year()) {
    return formatDateShort(date);
  } else {
    return formatDateLong(date);
  }
};

const formatDateTimeLong = (date: Date) => dayjs(date).format(FORMAT_DATE_TIME_LONG);

const formatDateTimeShort = (date: Date) => dayjs(date).format(FORMAT_DATE_TIME_SHORT);

export const formatDateTime = (date: Date) => {
  if (dayjs().year() === dayjs(date).year()) {
    return formatDateTimeShort(date);
  } else {
    return formatDateTimeLong(date);
  }
};

const padWithZeros = (numStr: string, width: number): string => {
  return numStr.length >= width ? numStr : '0'.repeat(width - numStr.length) + numStr;
};

export const formatCountdown = (date: Date) => {
  const totalSeconds = date.getTime() / 1000;

  const hours = Math.floor(totalSeconds / 3600).toFixed(0);
  const minutes = Math.floor((totalSeconds / 60) % 60).toFixed(0);
  const seconds = Math.floor(totalSeconds % 60).toFixed(0);

  return `${hours}:${padWithZeros(minutes, 2)}:${padWithZeros(seconds, 2)}`;
};

export const formatMessageDate = (date: string) => {
  const parsedDate = parseBackendDate(date);
  return formatDate(parsedDate);
};

export const formatTime = (date: string) => {
  const userTimeZone = dayjs.tz.guess();

  return dayjs.utc(date).tz(userTimeZone).format(FORMAT_TIME);
};
