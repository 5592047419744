import { PropsWithChildren } from 'react';

import { AuthProvider } from './AuthProvider';
import { CacheProvider } from './cache/CacheProvider';
import { FundraiserPageProvider } from './FundraiserPageProvider';
import { MessageProvider } from './MessageProvider';
import { MessagingStateProvider } from './MessagingStateProvider';
import { NotificationProvider } from './NotificationProvider';
import { PopupManagerProvider } from './PopupManagerProvider';

const AppProviders = ({ children }: PropsWithChildren) => {
  return (
    <AuthProvider>
      <MessagingStateProvider>
        <CacheProvider>
          <FundraiserPageProvider>
            <NotificationProvider>
              <PopupManagerProvider>
                <MessageProvider>{children}</MessageProvider>
              </PopupManagerProvider>
            </NotificationProvider>
          </FundraiserPageProvider>
        </CacheProvider>
      </MessagingStateProvider>
    </AuthProvider>
  );
};

export default AppProviders;
