import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { useLogout } from 'features/account';
import { usePaymentConfig } from 'providers/cache/cachedDataHooks';

import { MenuItem } from './MenuItem';
import { MenuData } from './menuTypes';

const Container = styled.div`
  display: flex;
  position: relative;
`;

const MenuItemsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 216px;
  margin: 36px 0 36px 24px;

  ${theme.mq.desktop} {
    flex-direction: row;
    margin: 0;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
  }
`;

const Gradient = styled.div`
  width: 20px;
  height: 100%;
  position: absolute;
  background: linear-gradient(90deg, #ffffff00 0%, #ffffff 100%);
  display: none;

  ${theme.mq.desktop} {
    display: flex;
  }
`;

const GradientLeft = styled(Gradient)`
  left: -1px;
  transform: rotate(180deg);
`;

const GradientRight = styled(Gradient)`
  right: -1px;
`;

type DisabledOptions = { [key in string]: boolean };
export const Menu = () => {
  const navigate = useNavigate();
  const { logout } = useLogout();
  const { paymentConfig } = usePaymentConfig();

  const disabledOptions: DisabledOptions = {
    'payment.header': !(paymentConfig?.availablePaymentMethodTypes?.includes('creditCard') ?? true),
  };

  const menuConfig: MenuData[] = [
    {
      nameKey: 'my-activity.header',
      url: '/my-profile/my-activity',
      subItems: [
        { nameKey: 'myProfile:my-activity.campaigns', url: '/my-profile/my-activity/campaigns' },
        {
          nameKey: 'myProfile:my-activity.hot-deals',
          url: '/my-profile/my-activity/selling',
          activeForUrls: [
            '/my-profile/my-activity/selling',
            '/my-profile/my-activity/donated',
            '/my-profile/my-activity/bought',
          ],
        },
      ],
    },
    { nameKey: 'account-settings.header', isDisabled: true },
    { nameKey: 'location.header', isDisabled: true },
    { nameKey: 'shipping-address.header', url: '/my-profile/shipping-address' },
    { nameKey: 'payment.header', url: '/my-profile/payment' },
    { nameKey: 'contact-support.header', onClick: () => navigate('/messenger/context/SUPPORT') },
    { nameKey: 'notifications.header', isDisabled: true },
    { nameKey: 'passwords.header', isDisabled: true },
    { nameKey: '2fa.header', isDisabled: true },
    { nameKey: 'become-fundraiser.header', isDisabled: true },
    { nameKey: 'logout', onClick: logout },
  ];

  const filteredMenuConfig = useMemo(
    () =>
      menuConfig.filter(menuOption => {
        return !disabledOptions[menuOption.nameKey];
      }),
    [menuConfig]
  );

  return (
    <Container data-testid={'my-profile-menu'}>
      <MenuItemsWrapper>
        {filteredMenuConfig.map(menuData => (
          <MenuItem key={menuData.nameKey} {...menuData} />
        ))}
      </MenuItemsWrapper>
      <GradientLeft />
      <GradientRight />
    </Container>
  );
};
