import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { RedTextButton } from 'components/buttons/RedTextButton';
import { useCampaignCategories } from 'providers/cache/cachedDataHooks';

import { CampaignsFilters } from './CampaignsFilters';

const MobileFiltersContainer = styled.div`
  background-color: ${theme.color.white};
  width: 100%;
`;

const ShowResultsButton = styled(RedTextButton)`
  width: 100%;
  border: 1px solid ${theme.color.red};
`;

const Spacer = styled.div`
  width: 100%;
  border-top: 1px solid ${theme.color.darkGray};
`;

const PaddedContainer = styled.div`
  padding: 24px;
`;

interface Props {
  toggleFilters: () => void;
}

export const MobileCampaignsFilters = ({ toggleFilters }: Props) => {
  const { t } = useTranslation('campaign');
  const { campaignCategories } = useCampaignCategories();

  return (
    <MobileFiltersContainer>
      <PaddedContainer>
        <CampaignsFilters categories={campaignCategories || []} />
      </PaddedContainer>
      <Spacer />
      <PaddedContainer>
        <ShowResultsButton onClick={toggleFilters}>{t('show-results')}</ShowResultsButton>
      </PaddedContainer>
    </MobileFiltersContainer>
  );
};
