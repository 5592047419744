import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import RedButton from 'components/buttons/RedButton';
import WhiteButton from 'components/buttons/WhiteButton';
import { HorizontalSpacer } from 'components/ui-elements/HorizontalSpacer';
import Spinner from 'components/ui-elements/Spinner';
import { useAuth } from 'providers/AuthProvider';
import { useCurrentUser } from 'providers/cache/cachedDataHooks';
import { AuctionDto, ItemDto } from 'services/item/itemService.dto';

import { isAuctionExpired } from './auction/auctionUtils';
import BiddersPreview from './auction/BiddersPreview';

const Container = styled.div`
  margin-top: 24px;
`;

interface Props {
  item: ItemDto;
  onBuyClick: () => void;
  onMakeOfferClick: () => void;
  onPlaceBidClick: () => void;
  auction?: AuctionDto;
  onBidPlaced: () => void;
}

export const ButtonsSection = (props: Props) => {
  const { t } = useTranslation('item');
  const { item, onBuyClick, onMakeOfferClick, onPlaceBidClick, auction, onBidPlaced } = props;
  const { logged } = useAuth();
  const { currentUser } = useCurrentUser();
  const isOwner = item.ownerId === currentUser?.id;

  const renderSpinner = () => (
    <Container>
      <Spinner />
    </Container>
  );

  if (logged && !currentUser) return renderSpinner();

  if (item.sellingMethod === 'AUCTION') {
    if (!auction) return renderSpinner();
    const isExpired = isAuctionExpired(auction);

    return (
      <Container>
        <RedButton data-testid={'place-bid-button'} onClick={onPlaceBidClick} disabled={isExpired || isOwner}>
          {isExpired ? t('auction-ended') : t('place-bid-button')}
        </RedButton>
        <BiddersPreview isAuctionOwner={isOwner} auction={auction} onBidPlaced={onBidPlaced} />
      </Container>
    );
  } else {
    return (
      <Container>
        {!!item.price && (
          <RedButton
            data-testid={'buy-button'}
            onClick={onBuyClick}
            disabled={item.itemStatus !== 'PUBLISHED' || isOwner}>
            {t('buy-button')}
          </RedButton>
        )}
        {item.itemStatus === 'PUBLISHED' && !isOwner && (
          <>
            {!!item.price && item.canAcceptLoHiOffers && <HorizontalSpacer padding={20} label={t('common:or')} />}
            {item.canAcceptLoHiOffers && (
              <WhiteButton data-testid={'make-offer-button'} onClick={onMakeOfferClick}>
                {t('make-offer-button')}
              </WhiteButton>
            )}
          </>
        )}
      </Container>
    );
  }
};
