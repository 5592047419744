import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Header } from 'features/my-profile/components/Header';
import { AddressesContainer } from 'features/my-profile/shipping-address/components/AddressesContainer';
import { OrderAddressesModule } from 'features/my-profile/shipping-address/OrderAddressesModule';
import { ShippingAddressProvider } from 'providers/ShippingAddressProvider';

const Container = styled.div`
  width: 700px;
  height: 450px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
`;

interface Props {
  onAddressSelected: (addressId: number) => void;
  selectedAddressId?: number;
}

export const ShipFromAddressPopup = ({ onAddressSelected, selectedAddressId }: Props) => {
  const { t } = useTranslation('addItem');

  return (
    <Container data-testid={'ship-from-address-popup'}>
      <Header>{t('delivery.ship-from-address.header')}</Header>
      <ShippingAddressProvider>
        <AddressesContainer>
          <OrderAddressesModule
            header={t('delivery.ship-from-address.set-header')}
            onAddressSelected={onAddressSelected}
            addressId={selectedAddressId}
          />
        </AddressesContainer>
      </ShippingAddressProvider>
    </Container>
  );
};
