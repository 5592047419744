import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { convertFormDataToEditItemDto, convertFormDataToNewItemDto } from 'features/add-item/context/converters';
import { CampaignDto } from 'services/campaign/campaignService.dto';
import { ImageDto, useUploadImages } from 'services/image/useUploadImages';
import {
  EditItemDto,
  ITEM_TYPES,
  ItemSellingMethod,
  ItemType,
  NewItemDto,
  SELLING_METHODS,
} from 'services/item/itemService.dto';
import { ItemWithAuctionDto } from 'services/item/withAuctionsData';
import { LocationDetailsDto } from 'services/utils/location/locationService.dto';
import { currentDate, parseBackendDate } from 'utils/backendDateParser';
import { dateWithDaysOffset } from 'utils/dateUtils';
import { isVideoLinkValid } from 'utils/videoLinkValidation';

import { MAX_PHOTO_SIZE, MAX_PHOTOS_COUNT, MAX_UNITS_QUANTITY, MIN_UNITS_QUANTITY } from './AddItemProvider';

export interface ItemFormData {
  type?: ItemType;
  photos: ImageDto[];
  videoUrl?: string;
  sellingMethod?: ItemSellingMethod;
  condition: string;
  endDateTime?: Date;
  price?: number;
  isNegotiable: boolean;
  unitsQuantity?: number;
  itemLocation?: LocationDetailsDto;
  canLocalPickUpDelivery: boolean;
  canNationwideShipping: boolean;
  weightUpTo?: number;
  supportedCampaign?: CampaignDto;
  title: string;
  description?: string;
  category: string;
  defaultShipFromAddressId?: number;
}

export const useItemForm = (
  maxItemPrice: number,
  postItem: (request: NewItemDto) => void,
  updateItem: (request: EditItemDto) => void,
  item?: ItemWithAuctionDto,
  supportedCampaign?: CampaignDto
) => {
  const { t } = useTranslation('addItem');
  const { photos, addPhoto, deletePhoto, movePhoto } = useUploadImages('ITEM', item?.imageUrlList, {
    maxNumberOfImages: MAX_PHOTOS_COUNT,
    maxFileSizeInBytes: MAX_PHOTO_SIZE,
  });

  useEffect(() => {
    formik.setFieldValue('photos', photos);
  }, [photos]);

  const onSubmit = (data: ItemFormData) => {
    if (!!item) {
      const editItemRequest: EditItemDto = convertFormDataToEditItemDto(data, item.id);
      updateItem(editItemRequest);
    } else {
      const newItemRequest: NewItemDto = convertFormDataToNewItemDto(data);
      postItem(newItemRequest);
    }
  };

  const initialValues = useMemo((): ItemFormData => {
    const initializeEndDateTime = () => {
      if (!item) return dateWithDaysOffset(new Date(), 1);
      if (item.sellingMethod !== 'AUCTION') return undefined;
      return item.auction?.endDateTime
        ? parseBackendDate(item?.auction?.endDateTime)
        : dateWithDaysOffset(new Date(), 1);
    };

    const initializePrice = () => {
      if (!!item?.auction) return item.auction.minimumPrice;
      return item?.price || undefined;
    };

    return {
      type: item?.itemType,
      photos,
      videoUrl: item?.videoUrl || undefined,
      sellingMethod: item?.sellingMethod,
      condition: item?.condition || '',
      endDateTime: initializeEndDateTime(),
      price: initializePrice(),
      isNegotiable: item ? item.canAcceptLoHiOffers : true,
      unitsQuantity: item?.unitsQuantity || 1,
      itemLocation: item?.itemLocation,
      canLocalPickUpDelivery: item?.canLocalPickUpDelivery || true,
      canNationwideShipping: item?.canNationwideShipping || false,
      weightUpTo: item?.weightUpTo || undefined,
      supportedCampaign,
      title: item?.title || '',
      description: item?.description || '',
      category: item?.category || '',
      defaultShipFromAddressId: item?.defaultShipFromAddressId || undefined,
    };
  }, [photos, item, supportedCampaign]);

  const validationSchema = Yup.object()
    .shape({
      type: Yup.string().oneOf(ITEM_TYPES).required(),
      photos: Yup.array()
        .min(1)
        .max(MAX_PHOTOS_COUNT)
        .of(
          Yup.object().shape({
            id: Yup.string().required(),
            isUploading: Yup.boolean(),
            safe: Yup.boolean(),
          })
        )
        .test('all-photos-uploaded', 'All photos must be uploading', photos =>
          photos?.every(photo => photo.isUploading === false)
        )
        .test('all-photos-safe', 'All photos must be safe', photos => photos?.every(photo => photo.safe === true)),
      videoUrl: Yup.string()
        .trim()
        .url(t('common:invalid-url'))
        .test('videoUrlValid', t('add-photos.video-not-found'), value => (value ? isVideoLinkValid(value) : true)),
      sellingMethod: Yup.string().oneOf(SELLING_METHODS).required(),
      condition: Yup.string().when('type', {
        is: (value: ItemType) => value === 'REGULAR',
        then: () => Yup.string().trim().required(),
      }),
      minimumPrice: Yup.number()
        .positive()
        .max(maxItemPrice, t('common.max-price-error', { maxPrice: maxItemPrice })),
      endDateTime: Yup.date().when('sellingMethod', {
        is: (value: ItemSellingMethod) => value === 'AUCTION',
        then: () => Yup.date().required().min(currentDate()),
      }),
      price: Yup.number()
        .positive()
        .max(maxItemPrice, t('common.max-price-error', { maxPrice: maxItemPrice }))
        .when('sellingMethod', {
          is: (value: ItemSellingMethod) => value === 'MULTIPLE',
          then: schema => schema.required(t('common.price-required-error')),
        })
        .test('price not modified', value => (item && item.unitsSold > 0 ? value === item.price : true))
        .test('starting price not modified', value =>
          item && item.auction && item.auction.bids.length > 0 ? value === item.auction.minimumPrice : true
        ),
      isNegotiable: Yup.boolean().when('price', {
        is: (value?: number) => !value,
        then: schema => schema.isTrue(),
      }),
      unitsQuantity: Yup.number().when('sellingMethod', {
        is: (value: ItemSellingMethod) => value === 'MULTIPLE',
        then: schema =>
          schema
            .required(t('set-multiple-items-quantity.quantity-error-min', { quantity: MIN_UNITS_QUANTITY }))
            .min(
              MIN_UNITS_QUANTITY,
              t('set-multiple-items-quantity.quantity-error-min', { quantity: MIN_UNITS_QUANTITY })
            )
            .max(
              MAX_UNITS_QUANTITY,
              t('set-multiple-items-quantity.quantity-error-max', { quantity: MAX_UNITS_QUANTITY })
            )
            .test(
              'quantity less than units sold',
              t('set-multiple-items-quantity.quantity-error-min', { quantity: item?.unitsSold }),
              value => (item && item.unitsSold > 0 ? value >= item.unitsSold : true)
            ),
        otherwise: schema => schema.test('quantity equals 1 for non MULTIPLE items', value => value === 1),
      }),
      itemLocation: Yup.object().required(),
      canLocalPickUpDelivery: Yup.boolean(),
      canNationwideShipping: Yup.boolean(),
      weightUpTo: Yup.number().when('canNationwideShipping', {
        is: (value: boolean) => value,
        then: schema => schema.required().positive(),
      }),
      supportedCampaign: Yup.object({
        id: Yup.number().required(),
      })
        .required()
        .test('campaign not modified', value =>
          item && item.unitsSold > 0 ? value.id === item.supportedCampaignId : true
        ),
      title: Yup.string().trim().required(t('confirm.title-error-required')),
      description: Yup.string()
        .trim()
        .when('type', {
          is: (value: ItemType) => value === 'SERVICE',
          then: schema => schema.required(t('confirm.description-error-required')),
        }),
      category: Yup.string().trim().required(t('confirm.category-error-required')),
    })
    .test('at least one delivery method selected', (values, ctx) => {
      if (values.canNationwideShipping || values.canLocalPickUpDelivery) return true;
      return ctx.createError({
        path: 'canNationwideShipping',
        message: 'at least one delivery method must be selected',
      });
    });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (!item) formik.setFieldValue('category', undefined);
  }, [formik.values.type]);

  return { ...formik, addPhoto, deletePhoto, movePhoto };
};
