import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { usePopup } from 'hooks/usePopup';

import ItemErrorPopup from './ItemErrorPopup';

export const useItemError = () => {
  const { showPopup, hidePopup } = usePopup();
  const { t } = useTranslation('addItem');
  const navigate = useNavigate();

  const handleContactSupport = () => {
    hidePopup(true);
    navigate('/messenger/context/SUPPORT');
  };

  const showAddItemErrorPopup = () => {
    showPopup(<ItemErrorPopup message={t('error.unable-to-list')} onContactPress={handleContactSupport} />, {
      keepPopups: true,
    });
  };

  const showDeleteItemErrorPopup = () => {
    showPopup(<ItemErrorPopup message={t('error.unable-to-delete')} onContactPress={handleContactSupport} />, {
      keepPopups: true,
    });
  };

  return { showAddItemErrorPopup, showDeleteItemErrorPopup };
};
