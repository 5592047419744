import { useTranslation } from 'react-i18next';

import { useConfirmPopup } from 'components/popups/useConfirmPopup';
import { useLoginPopup } from 'features/account';
import { usePopup } from 'hooks/usePopup';
import { useAuth } from 'providers/AuthProvider';
import { CampaignDto } from 'services/campaign/campaignService.dto';
import { ItemWithAuctionDto } from 'services/item/withAuctionsData';

import AddItemForm from './AddItemForm';
import EditItemForm from './EditItemForm';

export const useItemPopup = () => {
  const { t } = useTranslation('addItem');
  const { logged } = useAuth();
  const { showLoginPopup } = useLoginPopup();
  const { showPopup, hidePopup } = usePopup();
  const { showConfirmPopup } = useConfirmPopup();

  const handleClose = () => showConfirmPopup({ msg: t('quit-form-msg'), onYes: hidePopup });

  const checkLoginAndOpenPopup = (openFormPopup: () => void) => {
    if (!logged) showLoginPopup({ onLogged: () => openFormPopup() });
    else openFormPopup();
  };

  const showAddItemPopup = (campaign?: CampaignDto) => {
    checkLoginAndOpenPopup(() =>
      showPopup(<AddItemForm supportedCampaign={campaign} />, { onClose: handleClose, paddingVariant: 'FORM' })
    );
  };

  const showEditItemPopup = (item: ItemWithAuctionDto, campaign?: CampaignDto, onItemSaved?: () => void) => {
    const handleSaved = () => {
      hidePopup();
      onItemSaved && onItemSaved();
    };

    checkLoginAndOpenPopup(() =>
      showPopup(<EditItemForm item={item} supportedCampaign={campaign} onItemSaved={handleSaved} />, {
        onClose: handleClose,
        paddingVariant: 'FORM',
      })
    );
  };

  return { showAddItemPopup, showEditItemPopup };
};
