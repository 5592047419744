import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

import { theme } from 'assets/styles/theme';
import { useItemPopup } from 'features/add-item/form/useItemPopup';
import { useGetApp } from 'features/get-app/useGetApp';
import { useAutoSlide } from 'features/home/hooks/useAutoSlide';
import { useResponsive } from 'hooks/useResponsive';

import { ActionItem } from './ActionItem';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 40px;
  margin: 48px;
  padding-bottom: 80px;

  ${theme.mq.tablet} {
    margin: 24px;
  }
`;

const MobileSwiper = styled(Swiper)`
  margin-top: 24px;
  width: 100%;
  height: 300px;

  & .swiper-slide {
    max-width: 300px;
    height: 300px;
    opacity: 0.5;
  }

  & .swiper-slide-active {
    opacity: 1;
  }
`;

export const ActionSection = () => {
  const { t } = useTranslation('home');
  const { showGetApp } = useGetApp();
  const { isMobile } = useResponsive();
  const navigate = useNavigate();
  const { swiperRef, stopAutoSlide } = useAutoSlide(3000);
  const { showAddItemPopup } = useItemPopup();

  const actions = [
    {
      title: t('home:featured.give-item.title'),
      description: t('home:featured.give-item.description'),
      buttonLabel: t('home:featured.give-item.button'),
      onClick: () => showAddItemPopup(),
    },
    {
      title: t('home:featured.need-help.title'),
      description: t('home:featured.need-help.description'),
      buttonLabel: t('home:featured.need-help.button'),
      onClick: () => showGetApp({ appAreaType: 'ADD_CAMPAIGN' }),
    },
    {
      title: t('home:featured.hot-deals.title'),
      description: t('home:featured.hot-deals.description'),
      buttonLabel: t('home:featured.hot-deals.button'),
      onClick: () => navigate(`/items`),
    },
  ];

  const repeatedActions = [...actions, ...actions];

  return isMobile ? (
    <MobileSwiper
      ref={swiperRef}
      slidesPerView={'auto'}
      centeredSlides={true}
      spaceBetween={12}
      initialSlide={0}
      loop={true}
      watchOverflow={true}
      onTouchStart={stopAutoSlide}>
      {repeatedActions.map((item, index) => (
        <SwiperSlide key={index}>
          <ActionItem
            title={item.title}
            description={item.description}
            buttonLabel={item.buttonLabel}
            onButtonClick={item.onClick}
          />
        </SwiperSlide>
      ))}
    </MobileSwiper>
  ) : (
    <Container>
      {actions.map((item, index) => (
        <ActionItem
          key={index}
          title={item.title}
          description={item.description}
          buttonLabel={item.buttonLabel}
          onButtonClick={item.onClick}
        />
      ))}
    </Container>
  );
};
