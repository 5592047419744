import { usePopup } from 'hooks/usePopup';
import { PopupOptions } from 'providers/popupTypes';

import ConfirmPopup from './ConfirmPopup';

interface ConfirmPopupOptions {
  msg: string;
  onYes: () => Promise<void> | void;
  onNo?: () => void;
  options?: PopupOptions;
}

export const useConfirmPopup = () => {
  const { showPopup, hidePopup } = usePopup();

  const showConfirmPopup = ({ msg, onYes, onNo, options = { keepPopups: true } }: ConfirmPopupOptions) =>
    showPopup(
      <ConfirmPopup
        message={msg}
        onYesPress={() => Promise.resolve(onYes()).then(() => hidePopup())}
        onNoPress={() => {
          hidePopup();
          onNo?.();
        }}
      />,
      options
    );

  return { showConfirmPopup };
};
