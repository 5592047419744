import { useState } from 'react';
import { v4 as uuid } from 'uuid';

import { imageUploadService } from 'services/utils/imageUploadService';
import { fileToUrl } from 'utils/fileUtils';

import { imageService } from './imageService';
import { ImageRecognitionDto, UploadContext } from './imageService.dto';
import { UploadImageError, useUploadImagesError } from './useUploadImagesError';

export interface ImageDto {
  id: string;
  file?: File;
  localUrl?: string;
  url?: string;
  safe: boolean;
  isUploading: boolean;
  topLabel?: string;
  topLabelConfidence?: number;
}

export interface UploadImagesOptions {
  maxNumberOfImages?: number;
  maxFileSizeInBytes?: number;
}

const parseUrlsToImageDtos = (imgUrls?: string[]): ImageDto[] => {
  if (!imgUrls) return [];
  return imgUrls.map(url => {
    return {
      id: uuid(),
      url: url,
      isUploading: false,
      safe: true,
    };
  });
};

export const useUploadImages = (context: UploadContext, imgUrls?: string[], options?: UploadImagesOptions) => {
  const { handleError } = useUploadImagesError();
  const [photos, setPhotos] = useState<ImageDto[]>(parseUrlsToImageDtos(imgUrls));

  const addPhoto = async (file: File) => {
    const id = uuid();
    try {
      validatePhoto(file);
      await addUploadingPhoto(id, file);
      const recognitionResult = await uploadAndRecognizeImage(file);
      setUploadedPhoto(id, recognitionResult);
    } catch (e) {
      deletePhoto(id);
      handleError(e);
    }
  };

  const deletePhoto = (id: string) => {
    setPhotos(prevState => prevState.filter(photo => photo.id !== id));
  };

  const movePhoto = (from: number, to: number) => {
    setPhotos(prevPhotos => {
      const updatedPhotos = [...prevPhotos];
      if (from < 0 || to < 0 || from >= updatedPhotos.length || to >= updatedPhotos.length) return updatedPhotos;

      const [movedPhoto] = updatedPhotos.splice(from, 1);
      updatedPhotos.splice(to, 0, movedPhoto);

      return updatedPhotos;
    });
  };

  const addUploadingPhoto = async (id: string, file: File) => {
    try {
      const localUrl = await fileToUrl(file);
      const photo: ImageDto = { id, file, localUrl, safe: true, isUploading: true };
      setPhotos(prevState => [...prevState, photo]);
    } catch (e: any) {
      throw new UploadImageError(e.message, 'FILE_ERROR');
    }
  };

  const setUploadedPhoto = (id: string, recognitionResult: ImageRecognitionDto) => {
    const { safe, imageUrl, topLabel, topLabelConfidence } = recognitionResult;
    setPhotos(prevState =>
      prevState.map(photoItem => {
        if (photoItem.id === id) {
          return {
            ...photoItem,
            url: imageUrl,
            safe,
            isUploading: false,
            topLabel,
            topLabelConfidence,
          };
        }
        return photoItem;
      })
    );
  };

  const uploadAndRecognizeImage = async (file: File) => {
    try {
      const tempUrl = await imageUploadService.getPresignedPostUrl(context);
      await imageUploadService.uploadImageFromFile(tempUrl, file);
      const imageUrl = `${tempUrl.url}${tempUrl.fields.key}`;
      const { data } = await imageService.getImageContent(imageUrl, true);
      return data;
    } catch (e: any) {
      throw new UploadImageError(e.message, 'IMAGE_UPLOAD_ERROR');
    }
  };

  const validatePhoto = (file: File) => {
    const { maxFileSizeInBytes, maxNumberOfImages } = options || {};
    if (maxNumberOfImages && photos.length === maxNumberOfImages)
      throw new UploadImageError('Max number of images exceeded', 'IMAGES_LIMIT_EXCEEDED');
    if (maxFileSizeInBytes && file.size > maxFileSizeInBytes)
      throw new UploadImageError('File too big', 'IMAGE_TOO_LARGE');
  };

  return { photos, addPhoto, deletePhoto, movePhoto };
};
