import styled, { css } from 'styled-components';

import { theme } from 'assets/styles/theme';

import InputMessage from './InputMessage';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const { red, green, lighterGray } = theme.color;

export const containerStyle = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  gap: 10px;
`;

export const inputStyle = css<{ $error: boolean; $success: boolean }>`
  height: 45px;
  width: 100%;
  background: ${lighterGray};
  color: ${theme.color.lightBlack};
  border: 1px solid ${({ $error, $success }) => ($error ? red : $success ? green : lighterGray)};
  border-radius: 5px;
  box-shadow: unset;

  &:hover {
    border-color: ${lighterGray};
  }

  &:disabled {
    background-color: ${theme.color.disabled};
  }
`;

export const fontStyle = css`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
`;

export const focusStyle = css`
  border: 1px solid ${theme.color.darkGray};
  outline: 0;
`;

export const placeholderStyle = css`
  color: ${theme.color.placeholderGrey};
  opacity: 1;
`;

export interface BaseInputProps {
  value: string;
  placeholder?: string;
  errorMessage?: string;
  successMessage?: string;
  className?: string;
  testId?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  maxLength?: number;
}

interface Props {
  children: JSX.Element;
  errorMessage?: string;
  successMessage?: string;
  className?: string;
  testId?: string;
}

const BaseInputField = (props: Props) => {
  const { children, errorMessage, successMessage, className, testId } = props;

  return (
    <Container data-testid={testId} className={className}>
      {children}
      {errorMessage && <InputMessage message={errorMessage} type={'error'} />}
      {successMessage && <InputMessage message={successMessage} type={'success'} />}
    </Container>
  );
};

export default BaseInputField;
