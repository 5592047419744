import { useTranslation } from 'react-i18next';

import FormStepWrapper from 'components/form/FormStepWrapper';
import ChoicePicker, { ChoiceOption } from 'components/inputs/ChoicePicker';
import { useAddItem } from 'features/add-item/context/AddItemProvider';
import { useItemConditions } from 'providers/cache/cachedDataHooks';

const SelectCondition = () => {
  const { t } = useTranslation('addItem');
  const { formData, errors, setFieldValue } = useAddItem();
  const { itemConditions } = useItemConditions();

  const options: ChoiceOption[] =
    itemConditions?.map(condition => {
      return { key: condition.code, name: condition.shortDisplayName, isActive: formData.condition === condition.code };
    }) || [];

  const handleSelect = (key: string) => setFieldValue('condition', key);

  return (
    <FormStepWrapper
      header={t('select-condition.header')}
      description={t('select-condition.description')}
      isValid={!errors.condition}>
      <ChoicePicker options={options} onSelect={handleSelect} />
    </FormStepWrapper>
  );
};

export default SelectCondition;
