import { useEffect } from 'react';

import { usePaymentConfig } from 'providers/cache/cachedDataHooks';
import { clearFraudSessionId, storeFraudSessionId } from 'utils/localStorageData';

export const useFraudSession = () => {
  const { paymentConfig } = usePaymentConfig();

  useEffect(() => {
    if (!!paymentConfig) {
      const { finixEnvironment, finixMerchantId } = paymentConfig;

      if (!!finixMerchantId && !!finixEnvironment) {
        window.Finix.Auth(finixEnvironment, finixMerchantId, (sessionKey: string) => {
          storeFraudSessionId(sessionKey);
        });
      }
    }
    return () => clearFraudSessionId();
  }, [paymentConfig]);
};
