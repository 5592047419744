import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import WhiteButton from 'components/buttons/WhiteButton';
import Spinner from 'components/ui-elements/Spinner';

const ButtonWrapper = styled.div`
  width: 335px;

  ${theme.mq.phone} {
    width: 100%;
  }
`;

const Footer = styled.div`
  margin-top: 24px;
  width: 100%;
  height: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface Props {
  hasNextPage: boolean;
  loading: boolean;
  onClick: () => void;
}

export const LoadMoreButton = ({ hasNextPage, loading, onClick }: Props) => {
  const { t } = useTranslation('common');

  const displayLoadMore = hasNextPage && !loading;
  const displaySpinner = loading;
  const displayFooter = displayLoadMore || displaySpinner;

  if (!displayFooter) return null;

  return (
    <Footer>
      {displayLoadMore && (
        <ButtonWrapper data-testid={'load-more-button'}>
          <WhiteButton onClick={onClick}>{t('load-more')}</WhiteButton>
        </ButtonWrapper>
      )}
      {displaySpinner && <Spinner />}
    </Footer>
  );
};
