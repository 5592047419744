import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { CreateButton } from 'components/buttons/CreateButton';
import { LoadMoreButton } from 'components/buttons/LoadMoreButton';
import CampaignTile from 'components/campaign/campaign-tile/CampaignTile';
import { H4 } from 'components/typography/Headers';
import { useGetApp } from 'features/get-app/useGetApp';
import { useGridLayout } from 'hooks/useGridLayout';
import { useResponsive } from 'hooks/useResponsive';
import useMyCampaigns from 'services/campaign/useMyCampaigns';

import { NoContent } from './components/NoContent';
import { ReturnButton } from './components/ReturnButton';

const TilesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  gap: 24px;

  ${theme.mq.tablet} {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  margin-bottom: 24px;

  ${theme.mq.phone} {
    width: 100%;
    justify-content: space-between;
  }
`;

export const MyActivityCampaignsPage = () => {
  const { t } = useTranslation('myProfile');
  const { state, fetchNextPage } = useMyCampaigns();
  const { showGetApp } = useGetApp();
  const onCreateClick = () => showGetApp({ appAreaType: 'ADD_CAMPAIGN' });
  const navigate = useNavigate();
  const { isMobile } = useResponsive();
  const gridRef = useRef<HTMLDivElement>(null);
  const visibleCampaigns = useGridLayout(state.campaigns, gridRef, state.hasNextPage);

  const renderNoContent = () => (
    <NoContent
      text={t('my-activity.no-campaigns')}
      buttonText={t('my-activity.create-first')}
      onClick={onCreateClick}
    />
  );

  const resolveButtonText = () => (isMobile ? t('my-activity.create') : t('my-activity.create-campaign'));

  const renderCampaigns = () => (
    <>
      <TilesContainer ref={gridRef}>
        {visibleCampaigns.map(campaign => (
          <CampaignTile key={campaign.id} campaign={campaign} onClick={() => navigate(`/campaigns/${campaign.id}`)} />
        ))}
      </TilesContainer>
      <LoadMoreButton hasNextPage={state.hasNextPage} loading={state.loading} onClick={fetchNextPage} />
    </>
  );

  const renderContent = () => {
    if (state.loading) return null;
    if (state.campaigns && state.campaigns.length > 0) return renderCampaigns();
    return renderNoContent();
  };

  return (
    <div data-testid={'my-profile-my-activity-campaigns'}>
      <ReturnButton />
      <HeaderContainer>
        <H4>{t('my-activity.campaigns')}</H4>
        <CreateButton onClick={onCreateClick}>{resolveButtonText()}</CreateButton>
      </HeaderContainer>
      {renderContent()}
      <LoadMoreButton hasNextPage={state.hasNextPage} loading={state.loading} onClick={fetchNextPage} />
    </div>
  );
};
