import { usePopup } from 'hooks/usePopup';
import { AppAreaType } from 'services/notification/notificationService.dto';
import { ShareType } from 'services/utils/shareUtils';

import { GetApp } from './GetApp';

interface GetAppOptions {
  appAreaType?: AppAreaType;
  objectId?: number;
  header?: string;
  description?: string;
  shareType?: ShareType;
}

export const useGetApp = () => {
  const { showPopup, hidePopup } = usePopup();

  const showGetApp = (options?: GetAppOptions) => {
    showPopup(<GetApp {...options} onClose={hidePopup} />, {
      paddingVariant: 'NONE',
      hideClose: true,
      keepPopups: true,
    });
  };

  return { showGetApp };
};
