import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import RedButton from 'components/buttons/RedButton';
import { H4 } from 'components/typography/Headers';
import { Text1 } from 'components/typography/Texts';
import Spinner from 'components/ui-elements/Spinner';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  width: 100%;
`;

const Info = styled(Text1)`
  padding: 36px 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: flex-end;
`;

const YesButton = styled(RedButton)`
  flex: 1;
`;

const NoButton = styled(RedButton)`
  flex: 1;
  background-color: white;
  color: ${theme.color.red};
  border: 1px solid ${theme.color.red};
`;

interface Props {
  message: string;
  onYesPress: () => Promise<void>;
  onNoPress: () => void;
}

const ConfirmPopup = ({ message, onYesPress, onNoPress }: Props) => {
  const { t } = useTranslation();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleYes = () => {
    setIsProcessing(true);
    onYesPress()
      .catch(_ => {})
      .finally(() => setIsProcessing(false));
  };

  return (
    <Container data-testid={'confirm-popup'}>
      <H4>{t('confirm')}</H4>
      <Info>{message}</Info>

      <ButtonsContainer>
        {!isProcessing ? (
          <>
            <NoButton data-testid={'no-button'} role={'button'} onClick={onNoPress}>
              {t('no')}
            </NoButton>
            <YesButton data-testid={'yes-button'} role={'button'} onClick={handleYes}>
              {t('yes')}
            </YesButton>
          </>
        ) : (
          <Spinner />
        )}
      </ButtonsContainer>
    </Container>
  );
};

export default ConfirmPopup;
