import React, { createContext, ReactElement, useContext, useState } from 'react';

import { FormStepProps } from './form.types';

interface MultiStepFormContextType {
  activeStepIndex: number;
  totalStepsCount: number;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
}

const MultiStepFormContext = createContext<MultiStepFormContextType>(null!);

export interface MultiStepFormContextProps {
  children: (ReactElement<FormStepProps> | boolean)[];
}

const MultiStepFormProvider = ({ children }: MultiStepFormContextProps) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const formSteps = React.Children.toArray(children) as ReactElement<FormStepProps>[];
  const totalStepsCount = formSteps.length;

  const goToNextStep = () =>
    setActiveStepIndex(currentIdx => (currentIdx < totalStepsCount - 1 ? currentIdx + 1 : currentIdx));

  const goToPreviousStep = () => setActiveStepIndex(currentIdx => (currentIdx > 0 ? currentIdx - 1 : currentIdx));

  return (
    <MultiStepFormContext.Provider value={{ activeStepIndex, totalStepsCount, goToNextStep, goToPreviousStep }}>
      {formSteps.filter((step, index) => index === activeStepIndex)}
    </MultiStepFormContext.Provider>
  );
};

const useMultiStepForm = () => useContext(MultiStepFormContext);

export { MultiStepFormProvider, useMultiStepForm };
