import { usePopup } from 'hooks/usePopup';
import { ItemDto } from 'services/item/itemService.dto';

import { ListOfSold } from './ListOfSold';

interface Props {
  item: ItemDto;
  onClose?: () => void;
  onContactUser: (userId?: number) => void;
}

export const useListOfSoldPopup = () => {
  const { showPopup, hidePopup } = usePopup();

  const showListOfSoldPopup = ({ item, onClose, onContactUser }: Props) => {
    showPopup(<ListOfSold item={item} onClick={hidePopup} onContactUser={onContactUser} />, { onClose });
  };

  return { showListOfSoldPopup };
};
