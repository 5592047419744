import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import RadioButton from 'components/buttons/RadioButton';
import FormStepWrapper from 'components/form/FormStepWrapper';
import { ItemWeightSelector } from 'components/item/item-weight-selector/ItemWeightSelector';
import DropoffPlace from 'components/location/dropoff-place/DropoffPlace';
import LocationByZipPicker from 'components/location/LocationByZipPicker';
import { useAddItem } from 'features/add-item/context/AddItemProvider';
import ShipFromAddress from 'features/add-item/form/components/ship-from-address/ShipFromAddress';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;

const RadioButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: start;
`;

export const SetItemDeliveryContent = () => {
  const { t } = useTranslation('addItem');
  const { formData, setFieldValue, isEditMode } = useAddItem();
  return (
    <Container>
      <LocationByZipPicker
        value={formData.itemLocation}
        onChange={value => setFieldValue('itemLocation', value)}
        validZipLength={5}
        placeholder={t('delivery.zip-placeholder')}
      />
      <RadioButtonsContainer>
        <RadioButton
          data-testid={'radio-local-pickup'}
          checked={formData.canLocalPickUpDelivery}
          onChange={() => {}}
          onClick={() => setFieldValue('canLocalPickUpDelivery', !formData.canLocalPickUpDelivery)}>
          {t('common:local-pickup')}
        </RadioButton>
        <RadioButton
          data-testid={'radio-nationwide-shipment'}
          checked={formData.canNationwideShipping}
          onChange={() => {}}
          onClick={() => setFieldValue('canNationwideShipping', !formData.canNationwideShipping)}>
          {t('common:nationwide-shipping')}
        </RadioButton>
      </RadioButtonsContainer>
      {formData.sellingMethod === 'MULTIPLE' && formData.canNationwideShipping && isEditMode && (
        <ShipFromAddress
          addressId={formData.defaultShipFromAddressId}
          onAddressSelected={addressId => setFieldValue('defaultShipFromAddressId', addressId)}
        />
      )}
      {formData.canNationwideShipping && (
        <ItemWeightSelector
          selectedWeightUpTo={formData.weightUpTo}
          onWeightSelected={weightUpTo => setFieldValue('weightUpTo', weightUpTo)}
        />
      )}
      {formData.canNationwideShipping && !!formData.weightUpTo && <DropoffPlace location={formData.itemLocation} />}
    </Container>
  );
};

const SetItemDelivery = () => {
  const { t } = useTranslation('addItem');
  const { errors } = useAddItem();
  const isValid = !errors.itemLocation && !errors.canNationwideShipping && !errors.weightUpTo;

  return (
    <FormStepWrapper
      header={t('delivery.regular.header')}
      description={t('delivery.regular.description')}
      isValid={isValid}>
      <SetItemDeliveryContent />
    </FormStepWrapper>
  );
};

export default SetItemDelivery;
