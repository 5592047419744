import { useEffect, useState } from 'react';

import { useCurrentUser } from 'providers/cache/cachedDataHooks';
import { ItemDto } from 'services/item/itemService.dto';
import { PurchaseDto } from 'services/order/orderService.dto';

export const usePurchaseAccess = (
  itemId: number,
  isBuyer: boolean,
  item?: ItemDto,
  order?: PurchaseDto,
  orderError?: any
) => {
  const { currentUser } = useCurrentUser();

  const [userNotAllowed, setUserNotAllowed] = useState(false);

  useEffect(() => {
    if (!!item && !isBuyer) {
      if (currentUser && item.ownerId !== currentUser?.id) {
        setUserNotAllowed(true);
      }
    }
  }, [item, isBuyer, currentUser]);

  useEffect(() => {
    if (!!order) {
      if (order.itemId !== itemId) {
        setUserNotAllowed(true);
      } else if (currentUser && order.buyerId !== currentUser?.id && order.ownerId !== currentUser?.id) {
        setUserNotAllowed(true);
      }
    } else if (orderError?.response?.status === 404 || orderError?.response?.status === 403) {
      setUserNotAllowed(true);
    }
  }, [order, orderError, currentUser]);

  return {
    userNotAllowed,
    isLoading: !currentUser,
  };
};
