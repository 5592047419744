import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import deleteIcon from 'assets/svg/delete-icon.svg';
import editIcon from 'assets/svg/edit-paper-icon.svg';
import { Text1 } from 'components/typography/Texts';
import Spinner from 'components/ui-elements/Spinner';
import { useItemPopup } from 'features/add-item/form/useItemPopup';
import { useDeleteItem } from 'features/item/hooks/useDeleteItem';
import { useAuth } from 'providers/AuthProvider';
import { useCurrentUser } from 'providers/cache/cachedDataHooks';
import { CampaignDto } from 'services/campaign/campaignService.dto';
import { AuctionDto, ItemDto } from 'services/item/itemService.dto';
import { ItemWithAuctionDto } from 'services/item/withAuctionsData';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  justify-content: center;
`;

const Button = styled.div`
  display: flex;
  gap: 10px;
  color: ${theme.color.red};
  cursor: pointer;
`;

interface Props {
  item: ItemDto;
  auction?: AuctionDto;
  supportedCampaign?: CampaignDto;
  refreshItemData?: () => void;
  refreshAuctionData?: () => void;
}

export const ManageItemSection = ({ item, auction, supportedCampaign, refreshAuctionData, refreshItemData }: Props) => {
  const { t } = useTranslation();
  const { showEditItemPopup } = useItemPopup();
  const { deleteItem } = useDeleteItem();
  const { logged } = useAuth();
  const { currentUser } = useCurrentUser();

  if (!logged) return <></>;

  if (!currentUser)
    return (
      <Container>
        <Spinner />
      </Container>
    );

  const isOwner = item.ownerId === currentUser.id;
  const canEdit = item.itemStatus === 'PUBLISHED' || item.itemStatus === 'UNSOLD';

  if (!isOwner || !canEdit) return <></>;

  const itemWithAuction: ItemWithAuctionDto = { ...item, auction };

  const onItemSaved = () => {
    refreshAuctionData && auction && refreshAuctionData();
    refreshItemData && refreshItemData();
  };

  return (
    <Container>
      <Button onClick={() => showEditItemPopup(itemWithAuction, supportedCampaign, onItemSaved)}>
        <img src={editIcon} alt={t('edit')} />
        <Text1>{t('edit')}</Text1>
      </Button>
      <Button onClick={() => deleteItem(item.id)}>
        <img src={deleteIcon} alt={t('delete')} />
        <Text1>{t('delete')}</Text1>
      </Button>
    </Container>
  );
};
