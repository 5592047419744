import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import iphone from 'assets/svg/iphone-home.svg';
import ButtonBase from 'components/buttons/ButtonBase';
import { H2, H5, H6 } from 'components/typography/Headers';
import { Text1 } from 'components/typography/Texts';
import { openInNewTab } from 'components/ui-elements/StoreBanner';
import { useGetApp } from 'features/get-app/useGetApp';
import { resolveFoundUrl } from 'utils/webRedirections';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 72px;
  position: relative;
  gap: 48px;

  &::before {
    content: '';
    position: absolute;
    top: 100px;
    bottom: 100px;
    left: 0;
    right: 0;
    background-color: ${theme.color.blue};
    z-index: -1;
  }

  ${theme.mq.tablet} {
    padding: 48px 0;
    flex-direction: column-reverse;
    background-color: ${theme.color.blue};
  }
`;

const IphoneImg = styled.img`
  height: 900px;
  aspect-ratio: 1;
  object-fit: cover;
  object-position: 100% 0;
  width: 500px;

  ${theme.mq.tablet} {
    margin-left: 20px;
    height: 810px;
    width: 460px;
  }

  ${theme.mq.phone} {
    margin-left: 20px;
    height: 620px;
    width: 350px;
  }
`;

const TextContainer = styled.div`
  max-width: 620px;
  display: flex;
  flex-direction: column;
  gap: 48px;

  ${theme.mq.huge} {
    gap: 24px;
  }

  ${theme.mq.tablet} {
    gap: 12px;
    max-width: 474px;
    padding: 12px;
    text-align: center;
    align-items: center;
  }

  ${theme.mq.phone} {
    max-width: 474px;
    text-align: center;
    align-items: center;
    padding: 0 24px;
  }
`;

const ButtonsSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const StyledBlueButton = styled(ButtonBase)`
  border: 1px solid ${theme.color.white};
  background-color: ${theme.color.blue};
  color: ${theme.color.white};
`;

const StyledWhiteButton = styled(ButtonBase)`
  color: ${theme.color.blue};
`;

const Title = styled(H6)`
  color: white;
`;

const Header = styled(H2)`
  color: white;
`;

const Desc1 = styled(H5)`
  color: white;
`;

const Desc2 = styled(Text1)`
  color: white;
`;

export const FundraiserSection = () => {
  const { t } = useTranslation('home');
  const { showGetApp } = useGetApp();

  return (
    <Container data-testid={'easy-giving'}>
      <TextContainer>
        <Title>{t('fundraiser.title')}</Title>
        <Header>{t('fundraiser.header')}</Header>
        <Desc1>{t('fundraiser.first-desc')}</Desc1>
        <Desc2>{t('fundraiser.second-desc')}</Desc2>
        <ButtonsSection>
          <StyledWhiteButton onClick={() => showGetApp()}>{t('fundraiser.get-the-app')}</StyledWhiteButton>
          <StyledBlueButton onClick={() => openInNewTab(resolveFoundUrl())}>
            {t('fundraiser.more-about')}
          </StyledBlueButton>
        </ButtonsSection>
      </TextContainer>
      <IphoneImg src={iphone} />
    </Container>
  );
};
