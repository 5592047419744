import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import slugify from 'react-slugify';

import Carousel from 'components/carousel/Carousel';
import {
  ColumnsContainer,
  Container,
  ContentContainer,
  DetailsColumn,
  FloatingSectionContainer,
  MainColumn,
  PostSomethingAndOwnerContainer,
  Title,
} from 'components/containers/ItemCampaignContainers';
import { renderErrorOrSpinner } from 'components/containers/renderErrorOrSpinner';
import { LimitedText } from 'components/limited-text/LimitedText';
import { PostSomething } from 'components/post-something/PostSomething';
import ConversationAwareOwner from 'components/profile/ConversationAwareOwner';
import ActionPanel from 'components/ui-elements/ActionPanel';
import { ClosedInfo } from 'components/ui-elements/ClosedInfo';
import ObjectError from 'components/ui-elements/ObjectError';
import { ObjectNotFound } from 'components/ui-elements/ObjectNotFound';
import Spinner from 'components/ui-elements/Spinner';
import { useCampaignData } from 'services/campaign/useCampaignData';

import CampaignDetails from './components/CampaignDetails';
import SupportingItems from './components/SupportingItems';

interface Props {
  campaignId?: number;
}

export const CampaignPreview = (props: Props) => {
  const { campaignId } = useParams();
  const { content: campaign, error, loading } = useCampaignData(Number(props.campaignId || campaignId));
  const navigate = useNavigate();
  const { t } = useTranslation('campaign');

  useEffect(() => {
    if (!!campaign && !props.campaignId) {
      navigate(`/campaigns/${slugify(campaign.name)}/${campaign.id}`, { replace: true });
    }
  }, [campaign]);

  if (loading) return renderErrorOrSpinner(<Spinner displayLabel={true} />);
  if (error || !campaign) {
    if (error?.response?.status === 410) return renderErrorOrSpinner(<ObjectNotFound />);
    else return renderErrorOrSpinner(<ObjectError error={error} />);
  }

  return (
    <Container>
      {campaign.status === 'CLOSED' && <ClosedInfo title={t('campaign-closed')} />}
      <ContentContainer>
        <FloatingSectionContainer>
          <ColumnsContainer>
            <MainColumn>
              <Carousel photos={campaign.imageUrls} videoUrl={campaign.videoUrl} isCampaign={true} />
              <Title data-testid={'campaign-name'}>{campaign.name}</Title>
              <LimitedText description={campaign.description} />
            </MainColumn>
            <DetailsColumn>
              <CampaignDetails campaign={campaign} />
              <ActionPanel appArea={'CAMPAIGN'} objectId={campaign.id} />
            </DetailsColumn>
          </ColumnsContainer>
        </FloatingSectionContainer>
        <SupportingItems campaignId={campaign.id} campaignName={campaign.name} />
        <PostSomethingAndOwnerContainer>
          <PostSomething campaign={campaign} />
          <ConversationAwareOwner userId={campaign.ownerId} context={'CAMPAIGN'} objectId={campaign.id} />
        </PostSomethingAndOwnerContainer>
      </ContentContainer>
    </Container>
  );
};
