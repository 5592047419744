import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { ColumnsContainer, DetailsColumn, MainColumn } from 'components/containers/ItemCampaignContainers';
import AddressSelection from 'features/checkout/delivery/AddressSelection';
import DeliveryMethod from 'features/checkout/delivery/DeliveryMethod';
import LocalPickupInfo from 'features/checkout/delivery/LocalPickupDetails';
import PaymentSelection from 'features/checkout/payment/PaymentSelection';
import OrderSummary from 'features/checkout/summary/OrderSummary';
import { usePaymentConfig } from 'providers/cache/cachedDataHooks';
import { ItemDto } from 'services/item/itemService.dto';
import { PurchaseDto } from 'services/order/orderService.dto';
import { TransactionDto } from 'services/payment/paymentService.dto';

import ItemDetails from './ItemDetails';
import ItemHeader from './ItemHeader';
import { useItemCheckout } from './useItemCheckout';

const ContentSeparator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${theme.color.darkGray};
  margin: 24px 0;
`;

interface Props {
  item: ItemDto;
  payForOffer: boolean;
  onPurchasePaid: (purchase: PurchaseDto, transaction: TransactionDto) => void;
  existingOrder?: PurchaseDto;
}

const ItemCheckoutContent = ({ item, payForOffer, onPurchasePaid, existingOrder }: Props) => {
  const {
    deliveryType,
    setDeliveryType,
    isDeliveryTypeLocked,
    purchase,
    isProcessingPurchase,
    isProcessingPayment,
    checkoutCardStatus,
    checkoutTokenStatus,
    confirmCheckoutWithCard,
    confirmCheckoutWithToken,
  } = useItemCheckout(item, payForOffer, onPurchasePaid, existingOrder);

  const { paymentConfig } = usePaymentConfig();
  const displayPaymentSelection = paymentConfig?.availablePaymentMethodTypes.includes('creditCard');

  return (
    <ColumnsContainer data-testid={'item-checkout'}>
      <MainColumn>
        <ItemHeader item={item} />
        <ItemDetails item={item} />
        {!isDeliveryTypeLocked && (
          <DeliveryMethod
            deliveryType={deliveryType}
            onDeliveryChanged={setDeliveryType}
            isProcessing={isProcessingPayment}
          />
        )}
        {!isDeliveryTypeLocked && <ContentSeparator />}
        {deliveryType === 'PickUp' && <LocalPickupInfo item={item} />}
        {deliveryType === 'Shipment' && <AddressSelection isProcessing={isProcessingPayment} />}
        {displayPaymentSelection && <ContentSeparator />}
        {displayPaymentSelection && <PaymentSelection isProcessing={isProcessingPayment} />}
      </MainColumn>
      <DetailsColumn>
        <OrderSummary
          deliveryType={deliveryType}
          purchase={purchase}
          checkoutCardStatus={checkoutCardStatus}
          checkoutTokenStatus={checkoutTokenStatus}
          isProcessing={isProcessingPayment || isProcessingPurchase}
          confirmCheckoutWithCard={confirmCheckoutWithCard}
          confirmCheckoutWithToken={confirmCheckoutWithToken}
        />
      </DetailsColumn>
    </ColumnsContainer>
  );
};

export default ItemCheckoutContent;
