import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PURCHASE_ACTIONS } from 'assets/constants/purchaseActions';
import { theme } from 'assets/styles/theme';
import EditIcon from 'assets/svg/edit-icon.svg';
import { H6 } from 'components/typography/Headers';
import { Text2Bold } from 'components/typography/Texts';
import { OrderAddressPopup } from 'features/my-profile/my-activity/components/OrderAddressPopup';
import { useGlobalError } from 'hooks/useGlobalError';
import { usePopup } from 'hooks/usePopup';
import { useNotification } from 'providers/NotificationProvider';
import { orderService } from 'services/order/orderService';
import { PurchaseDto } from 'services/order/orderService.dto';

const AddressChangeContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const EditContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`;

const EditText = styled(Text2Bold)`
  color: ${theme.color.red};
`;

const ChangeAddressText = styled(H6)`
  font-weight: bold;
`;

interface Props {
  order: PurchaseDto;
  isBuyer: boolean;
  onAddressUpdated: () => void;
}

export const AddressChange = ({ order, isBuyer, onAddressUpdated }: Props) => {
  const { t } = useTranslation('myProfile');
  const { unknownError } = useGlobalError();
  const { showPopup, hidePopup } = usePopup();
  const { showNotification } = useNotification();
  const isShippable = order.shipmentRequired;
  const status = order.status;

  const canAddressChange = () => {
    return !isBuyer && isShippable && status && PURCHASE_ACTIONS[status].canAddressChange;
  };

  const handleAddressSelected = (addressId: number) => {
    orderService
      .updatePurchase(order.id, isBuyer ? { addressToId: addressId } : { addressFromId: addressId })
      .then(() => {
        hidePopup();
        showNotification({ title: t('tracked-item.purchase-address.notification-updated'), type: 'success' });
        onAddressUpdated();
      })
      .catch(() => unknownError());
  };

  const onEditClick = () => {
    const address = isBuyer ? order?.addressTo : order?.addressFrom;
    showPopup(<OrderAddressPopup onAddressSelected={handleAddressSelected} selectedAddress={address} />, {
      keepPopups: true,
    });
  };

  if (!canAddressChange()) return null;

  return (
    <AddressChangeContainer>
      <ChangeAddressText data-testid={'address-header'}>{t('tracked-item.my-address')}</ChangeAddressText>
      <EditContainer data-testid={'edit-btn'} onClick={() => onEditClick()}>
        <img src={EditIcon} alt={'edit icon'} />
        <EditText>{t('tracked-item.edit')}</EditText>
      </EditContainer>
    </AddressChangeContainer>
  );
};
