import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import PreviewIconSource from 'assets/svg/preview.svg';
import { ExternalLinkButton } from 'components/buttons/LinkButton';
import { TextButton2 } from 'components/typography/TextButtons';
import { CampaignDto } from 'services/campaign/campaignService.dto';

import { CampaignName } from './CampaignName';
import CampaignPhoto from './CampaignPhoto';
import { CampaignProgressBar } from './CampaignProgressBar';
import { DataContainer, TileContainer } from './containers';

const RowTileContainer = styled(TileContainer)`
  display: flex;
  flex-direction: row;
  cursor: default;
  align-items: center;
`;

const ScaledCampaignPhoto = styled(CampaignPhoto)`
  width: 33%;
`;

const ScaledDataContainer = styled(DataContainer)`
  width: 66%;
`;

const PreviewIcon = styled.img`
  width: 12px;
  height: 12px;
  margin-right: 6px;
  margin-top: 4px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
`;

interface Props {
  campaign: CampaignDto;
  onSupportClick?: () => void;
}

const SupportCampaignTile = ({ campaign, onSupportClick }: Props) => {
  const { t } = useTranslation('campaign');

  return (
    <RowTileContainer data-testid={'campaign-tile_' + campaign.id}>
      <ScaledCampaignPhoto
        image={campaign.imageUrls[0]}
        isHidden={campaign.isHidden ?? false}
        campaignStatus={campaign.status}
      />
      <ScaledDataContainer>
        <CampaignName data-testid={'campaign-name'}>{campaign.name}</CampaignName>
        <CampaignProgressBar value={campaign.fundsRaised} total={campaign.moneyGoal} />
        <ButtonsContainer>
          <ExternalLinkButton href={`/campaigns/${campaign.id}`} target='_blank'>
            <PreviewIcon src={PreviewIconSource} />
            {t('picker.preview-button')}
          </ExternalLinkButton>
          <TextButton2 onClick={onSupportClick}> {t('picker.support-button')}</TextButton2>
        </ButtonsContainer>
      </ScaledDataContainer>
    </RowTileContainer>
  );
};

export default SupportCampaignTile;
