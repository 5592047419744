import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import {
  HEADER_HEIGHT_DEFAULT,
  HEADER_HEIGHT_DESKTOP,
  HEADER_HEIGHT_PHONE,
  ONLY_LOGO_HEADER_HEIGHT_DESKTOP,
  ONLY_LOGO_HEADER_HEIGHT_PHONE,
} from 'components/nav/headerConstants';
import { useHeaderVisibility } from 'components/nav/useHeaderVisibility';
import { useNotification } from 'providers/NotificationProvider';
import { NotificationType } from 'providers/utils/notifications/notificationType';

import { NotificationItem } from './NotificationItem';

const calculateTop = (
  isNavbarVisible: boolean,
  isOnlyLogoHeader: boolean | undefined,
  defaultHeight: number,
  onlyLogoHeight?: number
) => {
  if (!isNavbarVisible) return '0';
  if (isOnlyLogoHeader) return onlyLogoHeight ? `${onlyLogoHeight}px` : `${defaultHeight}px`;
  return `${defaultHeight}px`;
};

export const Container = styled.div<{
  $isNavbarVisible: boolean;
  $isOnlyLogoHeader?: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: ${props => calculateTop(props.$isNavbarVisible, props.$isOnlyLogoHeader, HEADER_HEIGHT_DEFAULT)};
  z-index: ${theme.zIndex.notification};
  transition: top 0.5s;
  background-color: ${theme.color.white};
  gap: 2px;

  ${theme.mq.desktop} {
    top: ${props =>
      calculateTop(
        props.$isNavbarVisible,
        props.$isOnlyLogoHeader,
        HEADER_HEIGHT_DESKTOP,
        ONLY_LOGO_HEADER_HEIGHT_DESKTOP
      )};
  }

  ${theme.mq.phone} {
    top: ${props =>
      calculateTop(
        props.$isNavbarVisible,
        props.$isOnlyLogoHeader,
        HEADER_HEIGHT_PHONE,
        ONLY_LOGO_HEADER_HEIGHT_PHONE
      )};
  }
`;

interface Props {
  isOnlyLogoHeader?: boolean;
}

const NotificationPriorityMap = new Map<NotificationType, number>([
  ['error', 1],
  ['success', 1],
  ['info', 2],
]);

export const NotificationContainer = ({ isOnlyLogoHeader }: Props) => {
  const { notifications, hideNotification } = useNotification();
  const isNavbarVisible = useHeaderVisibility();

  const orderedNotifications = notifications.sort((a, b) => {
    if (NotificationPriorityMap.get(a.content.type) !== NotificationPriorityMap.get(b.content.type)) {
      return NotificationPriorityMap.get(a.content.type)! - NotificationPriorityMap.get(b.content.type)!;
    }
    return notifications.indexOf(a) - notifications.indexOf(b);
  });

  return (
    <Container $isNavbarVisible={isNavbarVisible} $isOnlyLogoHeader={isOnlyLogoHeader}>
      {orderedNotifications.map(notification => (
        <NotificationItem
          key={notification.id}
          notification={notification}
          onClose={() => hideNotification(notification.id!!)}
          onClick={() => {
            notification.content.callback?.();
            hideNotification(notification.id!!);
          }}
        />
      ))}
    </Container>
  );
};
