import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { RedTextButton } from 'components/buttons/RedTextButton';
import Share, { ShareProps } from 'features/share/Share';

import { useItemPopup } from './useItemPopup';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 70px;

  ${theme.mq.phone} {
    padding: 0;
  }
`;

const PostAnotherButton = styled(RedTextButton)`
  width: 100%;
  border: 1px solid ${theme.color.red};
`;

const GoToItem = styled(RedTextButton)`
  width: 100%;
  font-size: 14px;
`;

interface Props extends ShareProps {
  onGoToItem: () => void;
}

const ShareItemPopup = (props: Props) => {
  const { t } = useTranslation('addItem');
  const { showAddItemPopup } = useItemPopup();

  return (
    <Container data-testid={'share-item-popup'}>
      <Share {...props} />
      <PostAnotherButton onClick={() => showAddItemPopup()}>{t('share.post-another')}</PostAnotherButton>
      <GoToItem onClick={props.onGoToItem}>{t('share.go-to-item')}</GoToItem>
    </Container>
  );
};

export default ShareItemPopup;
