import { PropsWithChildren, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import { theme } from 'assets/styles/theme';
import closeIcon from 'assets/svg/close-popup.svg';

const OPACITY_TRANSITION_TIME = 300;
export type PaddingVariant = 'STANDARD' | 'REDUCED' | 'FORM' | 'NONE';

const paddingMap: Record<PaddingVariant, string> = {
  NONE: '0',
  STANDARD: '40px',
  FORM: '30px',
  REDUCED: '40px 40px 5px 40px',
};

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const Background = styled.div<{ $open: boolean; $hasBlur?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${theme.color.overlay};
  z-index: ${theme.zIndex.modal};
  pointer-events: auto;
  backdrop-filter: ${props => (props.$hasBlur ? 'blur(5px)' : 'none')};
  animation: ${props => (props.$open ? fadeIn : fadeOut)} ${OPACITY_TRANSITION_TIME}ms ease-in forwards;
`;

const Container = styled.div<{ $paddingVariant: PaddingVariant }>`
  position: absolute;
  inset: 0;
  background: ${theme.color.white};
  border-radius: 5px;
  outline: none;
  padding: 0;
  width: fit-content;
  height: fit-content;
  max-height: 90%;
  overflow: auto;
  max-width: 700px;
  min-width: ${props => (props.$paddingVariant === 'NONE' ? '0' : '500px')};
  left: 50%;
  right: 50%;
  top: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);

  ${theme.mq.phone} {
    width: 94%;
    max-height: 96%;
    max-width: none;
    min-width: initial;
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-track {
    background: ${theme.color.lighterGray};
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${theme.color.darkGray};
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${theme.color.darkerGray2};
    cursor: pointer;
  }
`;

const Content = styled.div<{ $paddingVariant: PaddingVariant }>`
  width: 100%;
  padding: ${props => paddingMap[props.$paddingVariant]};
  display: flex;
  justify-content: center;
  text-align: center;
`;

const CloseButton = styled.img`
  position: absolute;
  top: 23px;
  right: 24px;
  cursor: pointer;
`;

export interface Props extends PropsWithChildren {
  open: boolean;
  showCloseButton: boolean;
  onCloseClick: () => void;
  onAfterClose: () => void;
  paddingVariant?: PaddingVariant;
  hasBlur?: boolean;
}

const PopupBase = ({ children, open, showCloseButton, onCloseClick, onAfterClose, paddingVariant, hasBlur }: Props) => {
  useEffect(() => {
    if (!open) setTimeout(onAfterClose, OPACITY_TRANSITION_TIME);
  }, [open]);

  return (
    <Background $open={open} $hasBlur={hasBlur}>
      <Container $paddingVariant={paddingVariant || 'STANDARD'}>
        {showCloseButton && <CloseButton data-testid={'close-popup-button'} src={closeIcon} onClick={onCloseClick} />}
        <Content $paddingVariant={paddingVariant || 'STANDARD'}>{children}</Content>
      </Container>
    </Background>
  );
};

export default PopupBase;
