import { DropOffDistanceDto, DropOffPlaceDto } from 'services/shipment/shipmentService.dto';

import { DropOffTimeDto, NearestDropOffPlaceDto } from './types';

const TIME_ESTIMATION_SPEED_IN_KPH = 30;
const KILOMETERS_TO_MILES_RATIO = 0.621371;
const MINUTES_IN_HOUR = 60;

export const extractNearestDropOffPlace = (dropOffPlaces: DropOffPlaceDto[]): NearestDropOffPlaceDto | undefined => {
  if (dropOffPlaces.length === 0) return undefined;

  const nearestPlace = dropOffPlaces.sort((place1, place2) => place1.distance.value - place2.distance.value)[0];

  const normalizedDistance = extractNormalizedDistance(nearestPlace);
  const estimatedTime = estimateTime(normalizedDistance.value);

  return { ...nearestPlace, distance: normalizedDistance, time: estimatedTime };
};

export const estimateTime = (distanceInMiles: number): DropOffTimeDto => {
  const speedInMph = TIME_ESTIMATION_SPEED_IN_KPH * KILOMETERS_TO_MILES_RATIO;
  const estimatedTime = Math.max((distanceInMiles / speedInMph) * MINUTES_IN_HOUR, 1);

  return { value: estimatedTime, unit: 'minutes' };
};

const extractNormalizedDistance = (dropOffPlace: DropOffPlaceDto): DropOffDistanceDto => {
  const { value, unit } = dropOffPlace.distance;

  if (unit === 'km') {
    return { value: value * KILOMETERS_TO_MILES_RATIO, unit: 'mi' };
  } else {
    return { value, unit };
  }
};
