import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useConfirmPopup } from 'components/popups/useConfirmPopup';
import { useItemError } from 'features/add-item/form/error/useItemError';
import { ROUTE_ITEMS } from 'navigation/config/routesConfig';
import { itemService } from 'services/item/itemService';

export const useDeleteItem = () => {
  const { showConfirmPopup } = useConfirmPopup();
  const { showDeleteItemErrorPopup } = useItemError();
  const navigate = useNavigate();
  const { t } = useTranslation('addItem');

  const onAccept = async (itemId: number) => {
    return itemService
      .removeItem(itemId)
      .then(() => navigate(ROUTE_ITEMS))
      .catch(e => {
        showDeleteItemErrorPopup();
        throw e;
      });
  };

  const deleteItem = (itemId: number) => {
    showConfirmPopup({ msg: t('delete-form-msg'), onYes: () => onAccept(itemId) });
  };

  return { deleteItem };
};
