import { createContext, FC, ReactNode, useContext, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { MessageDialogButton } from 'features/messenger/components/NotificationMessage';
import { NotificationInputType } from 'providers/utils/notifications/notificationInputType';

import { NotificationType, SYSTEM_NOTIFICATION_TYPES } from './utils/notifications/notificationType';

export interface NotificationContent {
  title: string;
  description?: string;
  type: NotificationType;
  autoHide?: boolean;
  firstButton?: MessageDialogButton;
  secondButton?: MessageDialogButton;
  inputType?: NotificationInputType;
  priceInputPlaceholder?: string;
  callback?: () => void;
  attributes?: any;
  messengerOnly?: boolean;
}

export interface NotificationData {
  id: string;
  content: NotificationContent;
}

interface NotificationContextType {
  notifications: NotificationData[];
  showNotification: (content: NotificationContent) => void;
  hideNotification: (idToRemove: string) => void;
}
export const NotificationContext = createContext<NotificationContextType>(null!);

const SHOW_NOTIFICATION_DISABLED_ROUTES = ['/messenger'];

const NotificationProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [notifications, setNotifications] = useState<NotificationData[]>([]);

  const canShowNotification = (notification: NotificationData) => {
    const isDisabledRoute = SHOW_NOTIFICATION_DISABLED_ROUTES.some(route => window.location.pathname.startsWith(route));
    return SYSTEM_NOTIFICATION_TYPES.includes(notification.content.type) || !isDisabledRoute;
  };

  const setSingleNonSystemNotification = (notification: NotificationData) => {
    setNotifications((prevNotifications: NotificationData[]) => [
      ...prevNotifications.filter(it => SYSTEM_NOTIFICATION_TYPES.includes(it.content.type)),
      notification,
    ]);
  };

  const addNotification = (notification: NotificationData) => {
    if (!canShowNotification(notification)) return;

    if (!SYSTEM_NOTIFICATION_TYPES.includes(notification.content.type)) {
      setSingleNonSystemNotification(notification);
    } else {
      setNotifications((prevNotifications: NotificationData[]) => [...prevNotifications, notification]);
    }
  };

  const showNotification = (content: NotificationContent) => {
    if (content?.messengerOnly) return;
    const notification: NotificationData = {
      id: uuid(),
      content: { autoHide: true, ...content },
    };
    addNotification(notification);
  };

  const hideNotification = (idToRemove: string) => {
    setNotifications(prevNotifications => prevNotifications.filter(notification => notification.id !== idToRemove));
  };

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        showNotification,
        hideNotification,
      }}>
      {children}
    </NotificationContext.Provider>
  );
};

const useNotification = () => useContext(NotificationContext);

export { NotificationProvider, useNotification };
