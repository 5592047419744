import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUserLocation } from 'features/campaign/hooks/useUserLocation';
import { DeliveryType } from 'features/checkout/delivery/deliveryType';
import { useHotDealsSearchPhrase } from 'features/item/hooks/useHotDealsSearchPhrase';
import { ItemSellingMethod } from 'services/item/itemService.dto';
import { SearchMarketplaceItemsParams } from 'services/search/searchService.dto';
import { GeocodingDto } from 'services/shipment/shipmentService.dto';
import { ItemSortType } from 'services/sortType';

import { DEFAULT_SORT_TYPE, PageState } from './filtersUtils';

export const noContentFilters: SearchMarketplaceItemsParams = {
  nationwideShipping: true,
  localPickup: true,
  sortType: DEFAULT_SORT_TYPE,
  size: 8,
};

interface PriceRange {
  priceFrom: number;
  priceTo: number;
}

export interface FiltersParams extends SearchMarketplaceItemsParams {
  cityName?: string;
  deliveryType?: DeliveryType;
}

const initFilters: FiltersParams = {
  categoryCode: undefined,
  conditionCode: undefined,
  priceFrom: undefined,
  priceTo: undefined,
  lat: undefined,
  lon: undefined,
  radius: undefined,
  nationwideShipping: undefined,
  localPickup: undefined,
  deliveryType: 'All',
  sortType: DEFAULT_SORT_TYPE,
};

interface HotDealsFiltersContextType {
  filters: FiltersParams;
  pageState: PageState;
  updateFilters: (params: {
    sortType?: ItemSortType;
    deliveryType?: DeliveryType;
    categoryCode?: string;
    location?: GeocodingDto;
    sellingMethod?: ItemSellingMethod;
    conditionCode?: string;
    radius?: number;
    priceRange?: PriceRange;
  }) => void;
  resetFilters: () => void;
  hasActiveFilters: boolean;
  removeFilter: (
    filterType:
      | 'category'
      | 'location'
      | 'condition'
      | 'searchPhrase'
      | 'sellingMethod'
      | 'pickUpArea'
      | 'priceRange'
      | 'deliveryType'
      | 'radius',
    value?: string
  ) => void;
}

const HotDealsFiltersContext = createContext<HotDealsFiltersContextType>(null!);

const HotDealsFiltersProvider = ({ children }: PropsWithChildren) => {
  const userLocation = useUserLocation();

  const { searchPhrase, applySearchToFilters, applySearchToPageState } = useHotDealsSearchPhrase();
  const [pageState, setPageState] = useState<PageState>(applySearchToPageState('Default'));
  const [filters, setFilters] = useState<FiltersParams>(applySearchToFilters(initFilters));
  const navigate = useNavigate();

  useEffect(() => {
    setFilters(prevFilters => applySearchToFilters(prevFilters));
    setPageState(prevState => applySearchToPageState(prevState));
  }, [searchPhrase]);

  const updateFilters = ({
    deliveryType,
    sortType,
    categoryCode,
    location,
    sellingMethod,
    conditionCode,
    radius,
    priceRange,
  }: {
    deliveryType?: DeliveryType;
    sortType?: ItemSortType;
    pageState?: PageState;
    categoryCode?: string;
    location?: GeocodingDto;
    sellingMethod?: ItemSellingMethod;
    conditionCode?: string;
    radius?: number;
    priceRange?: PriceRange;
  }) => {
    setFilters(prevFilters => {
      const newFilters = { ...prevFilters };

      if (categoryCode) {
        newFilters.categoryCode = categoryCode;
      }

      if (conditionCode) {
        const currentConditions = newFilters.conditionCode?.split(',') || [];
        const isAlreadySelected = currentConditions.includes(conditionCode);

        const updatedConditions = isAlreadySelected
          ? currentConditions.filter(condition => condition !== conditionCode)
          : [...currentConditions, conditionCode];

        newFilters.conditionCode = updatedConditions.join(',');
      }

      if (sortType) {
        newFilters.sortType = sortType;
        newFilters.lat = undefined;
        newFilters.lon = undefined;
        if (sortType === 'NEAREST') {
          newFilters.lat = userLocation.lat;
          newFilters.lon = userLocation.lon;
        }
      }

      if (sellingMethod) {
        newFilters.sellingMethod = sellingMethod;
      }

      if (deliveryType) {
        newFilters.deliveryType = deliveryType;
        if (deliveryType === 'All') {
          newFilters.localPickup = undefined;
          newFilters.nationwideShipping = undefined;
        } else if (deliveryType === 'PickUp') {
          newFilters.localPickup = true;
          newFilters.nationwideShipping = false;
        } else if (deliveryType === 'Shipment') {
          newFilters.localPickup = false;
          newFilters.nationwideShipping = true;
        }
      }

      if (location) {
        newFilters.sortType = 'NEAREST';
        newFilters.lat = location.coordinates.latitude;
        newFilters.lon = location.coordinates.longitude;
        newFilters.cityName = location.city;
      }

      if (radius !== undefined) {
        newFilters.radius = radius;
      } else {
        delete newFilters.radius;
      }

      if (priceRange) {
        newFilters.priceFrom = priceRange.priceFrom;
        newFilters.priceTo = priceRange.priceTo;
      }

      return newFilters;
    });
    setPageState('Filtered');
  };

  const removeFilter = (
    filterType:
      | 'category'
      | 'location'
      | 'condition'
      | 'searchPhrase'
      | 'sellingMethod'
      | 'pickUpArea'
      | 'priceRange'
      | 'deliveryType'
      | 'radius',
    value?: string
  ) => {
    setFilters(prevFilters => {
      const newFilters = { ...prevFilters };

      if (filterType === 'category' && newFilters.categoryCode) {
        newFilters.categoryCode = undefined;
      } else if (filterType === 'location') {
        newFilters.lat = undefined;
        newFilters.lon = undefined;
        newFilters.cityName = undefined;
        newFilters.sortType = DEFAULT_SORT_TYPE;
      } else if (filterType === 'searchPhrase') {
        newFilters.searchPhrase = undefined;
        navigate({ search: undefined });
      } else if (filterType === 'condition' && newFilters.conditionCode) {
        const currentConditions = newFilters.conditionCode.split(',');
        const updatedConditions = currentConditions.filter(conditionCode => conditionCode !== value);

        newFilters.conditionCode = updatedConditions.length > 0 ? updatedConditions.join(',') : undefined;
      } else if (filterType === 'sellingMethod') {
        newFilters.sellingMethod = undefined;
      } else if (filterType === 'pickUpArea') {
        newFilters.radius = undefined;
      } else if (filterType === 'priceRange') {
        newFilters.priceTo = undefined;
        newFilters.priceFrom = undefined;
      } else if (filterType === 'deliveryType') {
        newFilters.deliveryType = 'All';
      } else if (filterType === 'radius') {
        newFilters.radius = undefined;
      }

      return newFilters;
    });
  };

  const resetFilters = () => {
    setFilters(initFilters);
    setPageState('Default');
    navigate({ search: '' });
  };

  const hasActiveFilters =
    !!filters.categoryCode ||
    filters.lat !== undefined ||
    filters.deliveryType !== 'All' ||
    filters.sellingMethod !== undefined ||
    !!filters.priceTo ||
    !!filters.priceFrom ||
    !!filters.conditionCode ||
    !!filters.cityName ||
    !!filters.radius;

  return (
    <HotDealsFiltersContext.Provider
      value={{ filters, pageState, updateFilters, resetFilters, hasActiveFilters, removeFilter }}>
      {children}
    </HotDealsFiltersContext.Provider>
  );
};

const useHotDealsFilters = () => useContext(HotDealsFiltersContext);

export { HotDealsFiltersProvider, useHotDealsFilters };
