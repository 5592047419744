import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import pointerIcon from 'assets/svg/pointer-map.svg';
import { Text1 } from 'components/typography/Texts';
import { usePopup } from 'hooks/usePopup';
import { ShippingAddressProvider, useShippingAddress } from 'providers/ShippingAddressProvider';

import { ShipFromAddressPopup } from './ShipFromAddressPopup';

const ShippingAddressContainer = styled.div`
  margin: 10px 0;
  text-align: start;
`;

const ShippingAddress = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 5px 10px;
  cursor: pointer;
  color: ${theme.color.darkerGray};
  text-decoration: underline;
`;

interface Props {
  addressId?: number;
  onAddressSelected: (addressId: number) => void;
}

const ShipFromAddress = ({ addressId, onAddressSelected }: Props) => {
  const { t } = useTranslation('addItem');
  const { showPopup, hidePopup } = usePopup();
  const { shippingAddresses } = useShippingAddress();

  const formatAddress = () => {
    const selectedAddress = addressId && shippingAddresses.filter(address => address.id === addressId)[0];
    if (!selectedAddress) return t('delivery.ship-from-address.placeholder');
    const { postCode, city, state } = selectedAddress;
    return `${city}, ${state} ${postCode}`;
  };

  const handleAddressSelected = (addressId: number) => {
    onAddressSelected(addressId);
    hidePopup();
  };

  const showShipFromAddressPopup = () =>
    showPopup(<ShipFromAddressPopup onAddressSelected={handleAddressSelected} selectedAddressId={addressId} />, {
      keepPopups: true,
      paddingVariant: 'FORM',
    });

  return (
    <ShippingAddressContainer>
      <Text1>{t('delivery.ship-from-address.header')}</Text1>
      <ShippingAddress onClick={showShipFromAddressPopup}>
        <img src={pointerIcon} alt={'pointer-icon'} />
        <Text1 data-testid={'ship-from-address'}>{formatAddress()}</Text1>
      </ShippingAddress>
    </ShippingAddressContainer>
  );
};

const ShipFromAddressWithContext = (props: Props) => {
  return (
    <ShippingAddressProvider>
      <ShipFromAddress {...props} />
    </ShippingAddressProvider>
  );
};

export default ShipFromAddressWithContext;
