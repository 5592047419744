const YT_LINK_REGEXP =
  /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
export const YT_VIDEO_ID_LENGTH = 11;

const RES_TYPES = {
  default: 'default',
  high: 'hqdefault',
  medium: 'mqdefault',
};

export const isVideoLinkValid = async (videoUrl: string) => {
  return resolveYTVideoLink(videoUrl)
    .then(resolvedUrl => !!resolvedUrl)
    .catch(() => false);
};

const resolveYTVideoLink = async (videoUrl: string) => {
  if (!videoUrl || videoUrl.trim().length === 0) {
    return null;
  }
  const match = videoLinkPatternMatch(videoUrl);
  if (match != null && match.length > 1 && match[1].length === YT_VIDEO_ID_LENGTH) {
    const ytLink = `https://www.youtube.com/watch?v=${match[1]}`;
    const videoResponse = await videoHeadResponse(ytLink);
    return videoResponse.ok ? ytLink : null;
  } else {
    return null;
  }
};

const videoLinkPatternMatch = (videoUrl: string) => {
  const trimmedUrl = videoUrl.trim();
  const decapitalizedUrl = trimmedUrl.charAt(0).toLowerCase() + trimmedUrl.slice(1);
  return decapitalizedUrl.match(YT_LINK_REGEXP);
};

const videoHeadResponse = async (videoUrl: string) => {
  return await fetch(resolveYTThumbnail(videoUrl), { method: 'HEAD' });
};

const resolveYTThumbnail = (url: string, resType?: string) => {
  return `https://img.youtube.com/vi/${getVideoId(url)}/${resType || RES_TYPES.high}.jpg`;
};

const getVideoId = (url: string) => {
  const result = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  const videoIdWithParams = result[2];

  if (videoIdWithParams !== undefined) {
    return videoIdWithParams.split(/[^0-9a-z_-]/i)[0];
  }

  return null;
};
