import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import iphone from 'assets/svg/iphone.svg';
import RedButton from 'components/buttons/RedButton';
import { H1, H4 } from 'components/typography/Headers';
import { useGetApp } from 'features/get-app/useGetApp';
import { resolveAboutUsUrl } from 'utils/webRedirections';

import { openInNewTab } from './StoreBanner';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${theme.mq.huge} {
    padding: 0 120px;
  }

  ${theme.mq.desktop} {
    padding: 0 72px;
  }

  ${theme.mq.tablet} {
    flex-direction: column;
  }
`;

const IphoneImg = styled.img`
  width: 600px;
  aspect-ratio: 1;
  object-fit: cover;
  object-position: 100% 0;

  ${theme.mq.huge} {
    width: 500px;
  }

  ${theme.mq.desktop} {
    width: 450px;
  }

  ${theme.mq.tablet} {
    width: 100%;
  }

  ${theme.mq.phone} {
    width: 80%;
  }
`;

const TextContainer = styled.div`
  max-width: 620px;
  display: flex;
  flex-direction: column;
  gap: 48px;

  ${theme.mq.huge} {
    gap: 24px;
  }

  ${theme.mq.tablet} {
    max-width: 474px;
    padding-top: 60px;
    text-align: center;
    align-items: center;
  }

  ${theme.mq.phone} {
    max-width: 474px;
    padding-top: 33px;
    text-align: center;
    align-items: center;
  }
`;

const Description = styled(H4)`
  font-weight: normal;
  white-space: break-spaces;
`;

const ButtonsSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;

  ${theme.mq.phone} {
    flex-direction: column;
    width: 180px;
  }
`;

const StyledWhiteButton = styled(RedButton)`
  border: 1px solid ${theme.color.red};
  background-color: ${theme.color.white};
  color: ${theme.color.red};
`;

export const EasyGiving = () => {
  const { t } = useTranslation('campaign');
  const { showGetApp } = useGetApp();

  return (
    <Container data-testid={'easy-giving'}>
      <TextContainer>
        <H1>{t('easy-giving.title')}</H1>
        <Description>{t('easy-giving.desc')}</Description>
        <ButtonsSection>
          <RedButton onClick={() => showGetApp()}>{t('easy-giving.get-app-btn')}</RedButton>
          <StyledWhiteButton onClick={() => openInNewTab(resolveAboutUsUrl())}>
            {t('easy-giving.more-about-btn')}
          </StyledWhiteButton>
        </ButtonsSection>
      </TextContainer>
      <IphoneImg src={iphone} />
    </Container>
  );
};
