import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { LoadMoreButton } from 'components/buttons/LoadMoreButton';
import { RedTextButton } from 'components/buttons/RedTextButton';
import { CampaignsTiles } from 'components/campaign/CampaignsTiles';
import { H4 } from 'components/typography/Headers';
import { Text2 } from 'components/typography/Texts';
import Spinner from 'components/ui-elements/Spinner';
import { useCampaigns } from 'features/campaign/hooks/useCampaigns';
import { useResponsive } from 'hooks/useResponsive';
import { useCampaignsFilters } from 'providers/CampaignFiltersProvider';

import { CampaignsNotFound } from './campaigns-not-found/CampaignsNotFound';
import { CampaignSortingDropdown } from './filters/CampaignSortingDropdown';
import { ClearCampaignsFiltersPanel } from './filters/ClearCampaingsFiltersPanel';

const Container = styled.div`
  padding: 48px 24px 48px 48px;

  ${theme.mq.tablet} {
    padding: 24px 36px;
  }

  ${theme.mq.phone} {
    padding: 12px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const SortContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const FilterButton = styled(RedTextButton)`
  border: 1px solid ${theme.color.red};
`;

const StyledSpinner = styled(Spinner)`
  height: 1000px;
  padding-top: 60px;
  justify-content: flex-start;
`;

interface Props {
  toggleFilters: () => void;
}

export const CampaignsList = ({ toggleFilters }: Props) => {
  const { t } = useTranslation('campaign');
  const { hasActiveFilters, resetFilters, pageState, filters, updateFilters } = useCampaignsFilters();
  const { state, fetchNextPage } = useCampaigns(filters, pageState);
  const hasCampaigns = state.campaigns.length > 0;
  const { isTablet, isMobile } = useResponsive();

  return (
    <Container data-testid={'campaigns-list'}>
      <HeaderContainer>
        <H4 data-testid={'campaigns-list-header'}>{t('campaigns')}</H4>
        <SortContainer data-testid={'campaigns-list-sort-container'}>
          {!isMobile && <Text2 data-testid={'items-list-sort-label'}>{t('sort-by')}</Text2>}
          <CampaignSortingDropdown
            testId={'campaigns-list-sorting-dropdown'}
            value={filters.sortType}
            onChange={sortType => updateFilters({ sortType })}
          />
          {isTablet && <FilterButton onClick={toggleFilters}>{t('filters-btn')}</FilterButton>}
        </SortContainer>
      </HeaderContainer>
      {hasActiveFilters && <ClearCampaignsFiltersPanel />}
      {hasCampaigns ? (
        <>
          <CampaignsTiles
            data-testid={'campaigns-list-home-item-tile-list'}
            campaigns={state.campaigns}
            hasNextPage={state.hasNextPage}
          />
          <LoadMoreButton hasNextPage={state.hasNextPage} loading={state.loading} onClick={fetchNextPage} />
        </>
      ) : !state.loading && !state.hasNextPage ? (
        <CampaignsNotFound resetFilters={resetFilters} />
      ) : (
        <StyledSpinner />
      )}
    </Container>
  );
};
