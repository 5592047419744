import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import upsLogo from 'assets/images/ups_logo.png';
import carIcon from 'assets/svg/car.svg';
import pointerIcon from 'assets/svg/pointer-map.svg';
import { Text1, Text1Bold } from 'components/typography/Texts';
import Spinner from 'components/ui-elements/Spinner';
import { LocationDetailsDto } from 'services/utils/location/locationService.dto';

import { useNearestDropOffPlace } from './useNearestDropOffPlace';

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: 24px;
`;

const ProviderLogo = styled.img`
  width: 75px;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: start;
`;

const DistanceContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

interface Props {
  location?: LocationDetailsDto;
}

const DropoffPlace = ({ location }: Props) => {
  const { t } = useTranslation('addItem');
  const { nearestDropOffPlace, isLoading } = useNearestDropOffPlace(location);

  if (isLoading)
    return (
      <Container>
        <Spinner />
      </Container>
    );
  if (!nearestDropOffPlace) return <></>;

  const extractAddress = () => {
    const { city, state, postalCode } = nearestDropOffPlace;
    return `${city}, ${state} ${postalCode}`;
  };

  const extractDistance = () => {
    const { value } = nearestDropOffPlace.distance;
    return t('delivery.drop-off-distance', { value: value.toFixed(1) });
  };

  const extractDuration = () => {
    const { value } = nearestDropOffPlace.time;
    return t('delivery.drop-off-time', { value: value.toFixed(0) });
  };
  return (
    <Container data-testid={'dropoff-place'}>
      <ProviderLogo src={upsLogo} />{' '}
      <DetailsContainer>
        <DistanceContainer>
          <img src={pointerIcon} alt={'pointer-icon'} />
          <Text1Bold data-testid={'dropoff-place-distance'}>{extractDistance()}</Text1Bold>
          <img src={carIcon} alt={'car-icon'} />
          <Text1Bold data-testid={'dropoff-place-time'}>{extractDuration()}</Text1Bold>
        </DistanceContainer>
        <Text1 data-testid={'dropoff-place-name'}>{nearestDropOffPlace.name}</Text1>
        <Text1 data-testid={'dropoff-place-address'}>{extractAddress()}</Text1>
      </DetailsContainer>
    </Container>
  );
};

export default DropoffPlace;
