import { useAuth } from 'providers/AuthProvider';
import { campaignService } from 'services/campaign/campaignService';
import { itemService } from 'services/item/itemService';
import { paymentService } from 'services/payment/paymentService';
import { reportService } from 'services/report/reportService';
import { userService } from 'services/user/userService';

import { useCachedData } from './useCachedData';

export const useCampaignCategories = () => {
  const cacheResult = useCachedData(context => context.campaignCategories, campaignService.fetchCategories);
  return { campaignCategories: cacheResult.data, campaignCategoriesStatus: cacheResult.status };
};

export const useItemCategories = () => {
  const cacheResult = useCachedData(context => context.itemCategories, itemService.fetchCategories);
  return { itemCategories: cacheResult.data, itemCategoriesStatus: cacheResult.status };
};

export const useItemConditions = () => {
  const cacheResult = useCachedData(context => context.itemConditions, itemService.fetchConditions);
  return { itemConditions: cacheResult.data, itemConditionsStatus: cacheResult.status };
};

export const usePaymentConfig = () => {
  const dataSupplier = () => paymentService.fetchClientConfiguration('USD');
  const cacheResult = useCachedData(context => context.paymentConfig, dataSupplier);
  return { paymentConfig: cacheResult.data, paymentConfigStatus: cacheResult.status };
};

export const usePriceRange = () => {
  const cacheResult = useCachedData(context => context.priceRange, itemService.fetchPriceRange);
  return { priceRange: cacheResult.data, priceRangeStatus: cacheResult.status };
};

export const useReportReasons = () => {
  const cacheResult = useCachedData(context => context.reportReasons, reportService.fetchReasons);
  return { reportReasons: cacheResult.data, reportReasonsStatus: cacheResult.status };
};

export const useCurrentUser = () => {
  const { logged } = useAuth();
  const cacheResult = useCachedData(context => context.currentUser, userService.fetchCurrentUser, { enabled: logged });
  return { currentUser: cacheResult.data, currentUserStatus: cacheResult.status };
};
