import styled from 'styled-components';

import fullScreenIcon from 'assets/svg/fullscreen-icon.svg';
import heartIcon from 'assets/svg/heart-icon.svg';

import { CarouselContainer, FullScreenIcon, HeartIcon } from './carousel.styles';
import { CarouselProps } from './carousel.types';
import { CarouselFullScreen } from './CarouselFullScreen';

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 5px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
`;

const StyledCarouselContainer = styled(CarouselContainer)<{ $image: string }>`
  background-image: url(${props => props.$image});
  background-size: cover;
  background-position: center;
`;

const PhotoDisplay = (props: CarouselProps) => {
  const { photos, isCampaign: canNotLike, onHeartClick, setFullScreen } = props;
  return (
    <StyledCarouselContainer $image={photos[0]}>
      <Image src={photos[0]} data-testid={'image'} />
      {!canNotLike && <HeartIcon src={heartIcon} onClick={onHeartClick} />}
      <FullScreenIcon src={fullScreenIcon} onClick={setFullScreen} />
      <CarouselFullScreen {...props} />
    </StyledCarouselContainer>
  );
};

export default PhotoDisplay;
