import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import PlaceholderIconSource from 'assets/svg/profile-placeholder.svg';
import { IconWrapper, RowContainer } from 'components/containers/Containers';
import { NavbarText } from 'components/typography/NavbarTexts';
import Spinner from 'components/ui-elements/Spinner';
import { useLoginPopup } from 'features/account';
import { useAuth } from 'providers/AuthProvider';
import { useCurrentUser } from 'providers/cache/cachedDataHooks';
import { convertToThumbnail300 } from 'utils/thumbnails';

const SpinnerContainer = styled(RowContainer)`
  width: fit-content;
`;

const LoginContainer = styled(SpinnerContainer)`
  cursor: pointer;
`;

const ProfileIcon = styled(IconWrapper)`
  width: 50px;
  height: 50px;
  margin-right: 24px;
  border-radius: 25px;
`;

interface Props {
  className?: string;
}

const AccountInfo = ({ className }: Props) => {
  const { t } = useTranslation();
  const { showLoginPopup } = useLoginPopup();
  const { logged } = useAuth();
  const { currentUser } = useCurrentUser();

  const myProfileUrl = logged ? '/my-profile' : '';

  const handleClick = (e: React.MouseEvent) => {
    if (!logged) {
      e.preventDefault();
      showLoginPopup();
    }
  };

  const getDisplayedName = () => {
    if (!logged) {
      return t('navbar.login');
    } else if (!!currentUser?.firstName && !!currentUser?.lastName) {
      return `${currentUser.firstName} ${currentUser.lastName}`;
    } else {
      return t('navbar.account');
    }
  };

  const getDisplayedPhoto = () => {
    if (!!currentUser?.imageUrl) {
      return convertToThumbnail300(currentUser.imageUrl);
    } else {
      return PlaceholderIconSource;
    }
  };

  if (logged && !currentUser) {
    return (
      <SpinnerContainer className={className}>
        <Spinner />
      </SpinnerContainer>
    );
  }

  return (
    <Link to={myProfileUrl} onClick={handleClick}>
      <LoginContainer data-testid={'account-info'} className={className}>
        <ProfileIcon data-testid={'profile-photo'} src={getDisplayedPhoto()} />
        <NavbarText data-testid={'profile-name'}>{getDisplayedName()}</NavbarText>
      </LoginContainer>
    </Link>
  );
};

export default AccountInfo;
