import styled from 'styled-components';

import { theme } from 'assets/styles/theme';

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const IconWrapper = styled.img`
  cursor: pointer;
`;

export const LoadingContainer = styled.div`
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FiltersContainer = styled.div`
  width: 300px;
  padding: 48px 48px 0 24px;
`;

export const HomeContentContainer = styled.div`
  width: 70%;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 300px;
  border-left: 1px solid ${theme.color.darkGray};

  ${theme.mq.tablet} {
    width: 100%;
  }
`;

export const HomeContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: ${theme.maxWidthContainer}px;
  width: 100%;
  margin: 0 auto;
`;
