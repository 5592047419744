import { useEffect, useState } from 'react';

import { shipmentService } from 'services/shipment/shipmentService';
import { LocationDetailsDto } from 'services/utils/location/locationService.dto';

import { extractNearestDropOffPlace } from './extractNearestDropOffPlace';
import { NearestDropOffPlaceDto } from './types';

export const useNearestDropOffPlace = (location?: LocationDetailsDto) => {
  const [nearestDropOffPlace, setNearestDropOffPlace] = useState<NearestDropOffPlaceDto>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!location) {
      setNearestDropOffPlace(undefined);
      setIsLoading(false);
    } else {
      const { city, zipCode, state, latitude, longitude } = location;

      setIsLoading(true);
      shipmentService
        .fetchDropOffPlaces(city!, zipCode!, state!, latitude!, longitude!)
        .then(response => setNearestDropOffPlace(extractNearestDropOffPlace(response.data)))
        .catch(() => setNearestDropOffPlace(undefined))
        .finally(() => setIsLoading(false));
    }
  }, [location]);

  return { nearestDropOffPlace, isLoading };
};
