import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { useCurrentUser } from 'providers/cache/cachedDataHooks';
import { useSoldStats } from 'services/order/useSoldStats';
import useOwnerMoneyCollected from 'services/useOwnerMoneyCollected';

import { UserDonationInfo } from './UserDonationInfo';
import { UserVerificationInfo } from './UserVerificationInfo';

const Container = styled.div`
  border: 1px solid ${theme.color.borderGray};
  border-radius: 5px;
  flex: 1;

  ${theme.mq.phone} {
    max-width: 100%;
  }
`;

const Spacer = styled.div`
  margin: 0 24px;
  border-top: 1px solid ${theme.color.borderGray};
`;

export const UserSummary = () => {
  const { currentUser } = useCurrentUser();
  const { content: itemCount } = useSoldStats(currentUser?.id);
  const { ownerMoneyCollected } = useOwnerMoneyCollected(currentUser!!);
  const isAnyVerified =
    currentUser?.fundraiserVerificationCompleted || currentUser?.phoneVerified || currentUser?.facebookVerified;

  return (
    <Container>
      <UserDonationInfo
        fundraised={ownerMoneyCollected.content?.moneyFundraised}
        donations={ownerMoneyCollected.content?.moneyDonated}
        itemCount={itemCount?.soldCount ?? 0}
      />
      {isAnyVerified && (
        <>
          <Spacer />
          <UserVerificationInfo
            isVerifiedForPayments={currentUser?.fundraiserVerificationCompleted}
            isConfirmedPhone={currentUser?.phoneVerified}
            isConfirmedFacebook={currentUser?.facebookVerified}
          />
        </>
      )}
    </Container>
  );
};
