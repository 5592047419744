import { useTranslation } from 'react-i18next';

import regularTypeIcon from 'assets/svg/item-type-regular-icon.svg';
import serviceTypeIcon from 'assets/svg/item-type-service-icon.svg';
import SelectWithArrowButton from 'components/buttons/SelectWithArrowButton';
import FormStepWrapper from 'components/form/FormStepWrapper';
import { useMultiStepForm } from 'components/form/MultiStepFormProvider';
import { useAddItem } from 'features/add-item/context/AddItemProvider';
import { ItemType } from 'services/item/itemService.dto';

const SelectType = () => {
  const { t } = useTranslation('addItem');
  const { formData, setFieldValue, errors } = useAddItem();
  const { goToNextStep } = useMultiStepForm();

  const handleSelected = (type: ItemType) => {
    setFieldValue('type', type);
    goToNextStep();
  };

  return (
    <FormStepWrapper
      header={t('contribute.header')}
      description={t('contribute.description')}
      isValid={!errors.type}
      isNextHidden={!formData.type}>
      <SelectWithArrowButton
        onClick={() => handleSelected('REGULAR')}
        label={t('contribute.type-regular.title')}
        subLabel={t('contribute.type-regular.description')}
        selected={formData.type === 'REGULAR'}
        testId={'add-item-select-type-regular'}>
        <img src={regularTypeIcon} alt={t('contribute.type-regular.title')} />
      </SelectWithArrowButton>
      <SelectWithArrowButton
        onClick={() => handleSelected('SERVICE')}
        label={t('contribute.type-service.title')}
        subLabel={t('contribute.type-service.description')}
        selected={formData.type === 'SERVICE'}
        testId={'add-item-select-type-service'}>
        <img src={serviceTypeIcon} alt={t('contribute.type-service.title')} />
      </SelectWithArrowButton>
    </FormStepWrapper>
  );
};

export default SelectType;
