import { AuctionStatus } from 'services/item/itemService.dto';
import { formatCountdown, formatDate } from 'utils/dateFormatter';

const ENDING_SOON_OFFSET_HOURS = 72;

type EndDateTimeStatus = 'NOT_ENDING_SOON' | 'ENDING_SOON' | 'ENDED';

interface Result {
  status: EndDateTimeStatus;
  endInfo: string;
}

export const getAuctionEndStatus = (status: AuctionStatus, currentDateTime: Date, endDateTime: Date): Result => {
  if (hasEnded(status, currentDateTime, endDateTime)) {
    return { status: 'ENDED', endInfo: '' };
  } else if (isEndingSoon(currentDateTime, endDateTime)) {
    return { status: 'ENDING_SOON', endInfo: formatCountdown(getTimeDiff(currentDateTime, endDateTime)) };
  } else {
    return { status: 'NOT_ENDING_SOON', endInfo: formatDate(endDateTime) };
  }
};

const hasEnded = (status: AuctionStatus, currentDateTime: Date, endDateTime: Date): boolean => {
  return status !== 'ONGOING' || currentDateTime > endDateTime;
};

const isEndingSoon = (currentDateTime: Date, endDateTime: Date): boolean => {
  const timeDiff = getTimeDiff(currentDateTime, endDateTime);
  return timeDiff.getTime() <= ENDING_SOON_OFFSET_HOURS * 60 * 60 * 1000;
};

const getTimeDiff = (currentDateTime: Date, endDateTime: Date) => {
  return new Date(endDateTime.getTime() - currentDateTime.getTime());
};
