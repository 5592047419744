import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { usePopup } from 'hooks/usePopup';
import { useNotification } from 'providers/NotificationProvider';
import { PurchaseDto } from 'services/order/orderService.dto';

import { ReturnAndRefund } from './ReturnAndRefund';

export const useReturnAndRefundPopup = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showPopup, hidePopup } = usePopup();
  const { showNotification } = useNotification();

  const refundRequested = (refundReason: string) => {
    showNotification({
      title: t('notifications:success-action'),
      type: 'success',
    });
    navigate('/messenger/context/SUPPORT', { state: { message: refundReason } });
  };
  const invalidRequest = () => {
    showNotification({
      title: t('notifications:invalid-action'),
      type: 'error',
    });
  };

  const showReturnAndRefundPopup = (order: PurchaseDto) => {
    if (order.refundRequested) {
      navigate('/messenger/context/SUPPORT');
    } else {
      showPopup(
        <ReturnAndRefund
          order={order}
          hidePopup={hidePopup}
          onInvalidRequest={invalidRequest}
          onRefundRequested={refundRequested}
        />
      );
    }
  };

  return { showReturnAndRefundPopup };
};
