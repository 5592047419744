import { useEffect } from 'react';
import { AxiosPromise } from 'axios';

import { CacheOptions, CacheResult, CacheState } from './cachedDataTypes';
import { CacheContextType, useCache } from './CacheProvider';

export const useCachedData = <T>(
  cacheAccessor: (context: CacheContextType) => CacheState<T>,
  dataSupplier: () => AxiosPromise<T>,
  options?: CacheOptions
): CacheResult<T> => {
  const cachedDataContext = useCache();
  const cache = cacheAccessor(cachedDataContext);
  const enabled = options?.enabled ?? true;

  useEffect(() => {
    if (enabled) {
      fetchData();
    } else {
      clearData();
    }
  }, [enabled]);

  const fetchData = async () => {
    if (cache.status === 'fetched' || cache.requestInProgress.current) return;

    try {
      cache.requestInProgress.current = true;
      cache.setStatus('fetching');

      const { data } = await dataSupplier();

      // recheck condition in case cache was disabled in meantime
      if (cache.requestInProgress.current) {
        cache.setData(data);
        cache.setStatus('fetched');
      }
    } catch (exception) {
      cache.setData(null);
      cache.setStatus('notFetched');
    } finally {
      cache.requestInProgress.current = false;
    }
  };

  const clearData = () => {
    cache.requestInProgress.current = false;
    cache.setStatus('notFetched');
    cache.setData(null);
  };

  return { data: cache.data, status: cache.status };
};
