import styled from 'styled-components';

import VipIcon from 'assets/svg/bidder-vip-icon.svg';
import PlaceholderIcon from 'assets/svg/profile-placeholder.svg';
import { Text1 } from 'components/typography/Texts';
import { BidderDto } from 'features/item/components/auction/bidderType';
import { extractUserName } from 'utils/extractUserName';
import { formatMoneyInteger } from 'utils/numberFormatter';
import { convertToThumbnail300 } from 'utils/thumbnails';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  align-items: center;
  flex-direction: row;
  margin-bottom: 15px;
`;

const MiddleContainer = styled.div`
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const NameIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PhotoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Photo = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 45px;
  object-fit: cover;
`;

const NameText = styled(Text1)``;

const StyledVipIcon = styled.img`
  margin-left: 5px;
  width: 16px;
  height: 16px;
`;

const BidAmount = styled(Text1)`
  font-weight: 600;
`;

interface Props {
  bidder: BidderDto;
}

const BidderItem = ({ bidder }: Props) => {
  return (
    <Container data-testid={'auction-bidder'}>
      <PhotoWrapper>
        <Photo src={bidder.imageUrl ? convertToThumbnail300(bidder.imageUrl) : PlaceholderIcon} alt={'Bidder photo'} />
      </PhotoWrapper>
      <MiddleContainer>
        <NameIconContainer>
          <NameText data-testid={'bidder-name'}>{extractUserName(bidder)}</NameText>
          {bidder.vip && <StyledVipIcon src={VipIcon} alt={'Vip icon'} />}
        </NameIconContainer>
        <BidAmount>{formatMoneyInteger(bidder.bid.amount)}</BidAmount>
      </MiddleContainer>
    </Container>
  );
};

export default BidderItem;
