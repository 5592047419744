import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import profilePlaceholder from 'assets/svg/profile-placeholder.svg';
import { H5 } from 'components/typography/Headers';
import { Caption } from 'components/typography/Texts';
import { useContact } from 'features/messenger/hooks/useContact';
import { ConversationMessagingContext } from 'services/message/messageService.dto';
import { useSoldStats } from 'services/order/useSoldStats';
import useOwnerMoneyCollected from 'services/useOwnerMoneyCollected';
import { usePublicUserData } from 'services/user/usePublicUserData';
import { parseBackendDate } from 'utils/backendDateParser';
import { formatDate } from 'utils/dateFormatter';
import { extractUserName } from 'utils/extractUserName';
import { formatNullableMoneyInteger } from 'utils/numberFormatter';
import { convertToThumbnail300 } from 'utils/thumbnails';

import ContactOwnerButton from './ContactOwnerButton';
import OwnerStatistics from './OwnerStatistics';
import { OwnerVerificationInfo } from './OwnerVerificationInfo';
import UserDisplayName from './UserDisplayName';

const BorderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 460px;
  border: 1px solid ${theme.color.darkGray};
  border-radius: 5px;
  padding: 36px 36px;

  ${theme.mq.huge} {
    width: 380px;
  }
  ${theme.mq.tablet} {
    width: 100%;
    padding: 24px 24px;
  }
  ${theme.mq.phone} {
    padding: 12px 12px;
  }
`;

const SellerIcon = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50px;
  overflow: hidden;
  margin: 0 auto 8px auto;
`;

const SellerName = styled(H5)`
  margin: 5px 0;
  text-align: center;
`;

const MiddleSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const JoinDateLabel = styled(Caption)`
  color: ${theme.color.darkestGray};
`;

const VerifiedInfoSectionWrapper = styled.div`
  flex-direction: row;
  justify-content: center;
`;

const BottomView = styled.div`
  display: flex;
  margin-top: 18px;
  justify-content: center;
`;

export interface OwnerProps {
  userId: number;
  context: ConversationMessagingContext;
  objectId: number;
}

export const Owner = ({ userId, context, objectId }: OwnerProps) => {
  const { t } = useTranslation('user');
  const { content: ownerDetails } = usePublicUserData(userId);
  const { content: itemsSold } = useSoldStats(userId);
  const { ownerMoneyCollected } = useOwnerMoneyCollected(ownerDetails);
  const { contactOwner } = useContact(context);

  const fundraisedText = t('owner.fundraised');
  const donationsText = t('owner.donations');
  const itemsDonatedText = t('owner.items-donated', { count: itemsSold?.soldCount });

  return (
    <>
      {!!ownerDetails && (
        <BorderContainer data-testid={'owner-container'}>
          <SellerIcon>
            <img
              data-testid={'owner-image'}
              style={{ width: 100, height: 100, objectFit: 'cover' }}
              src={ownerDetails.imageUrl ? convertToThumbnail300(ownerDetails.imageUrl) : profilePlaceholder}
              alt="Seller's profile"
            />
          </SellerIcon>
          <UserDisplayName isVip={ownerDetails.vip}>
            <SellerName data-testid={'owner-name'}>{extractUserName(ownerDetails)}</SellerName>
          </UserDisplayName>
          <MiddleSectionWrapper>
            <JoinDateLabel>
              {t('owner.join-date', {
                joinDate: formatDate(parseBackendDate(ownerDetails.joinDate)),
              })}
            </JoinDateLabel>
            <OwnerStatistics
              items={
                ownerDetails.fundraiserVerificationCompleted
                  ? [
                      {
                        id: 1,
                        name: fundraisedText,
                        value: formatNullableMoneyInteger(ownerMoneyCollected.content?.moneyFundraised),
                      },
                      {
                        id: 2,
                        name: donationsText,
                        value: formatNullableMoneyInteger(ownerMoneyCollected.content?.moneyDonated),
                      },
                      { id: 3, name: itemsDonatedText, value: itemsSold?.soldCount },
                    ]
                  : [
                      { id: 1, name: itemsDonatedText, value: itemsSold?.soldCount },
                      {
                        id: 2,
                        name: donationsText,
                        value: formatNullableMoneyInteger(ownerMoneyCollected.content?.moneyDonated),
                      },
                    ]
              }
            />
            <VerifiedInfoSectionWrapper>
              <OwnerVerificationInfo
                isVerifiedForPayments={ownerDetails.fundraiserVerificationCompleted}
                isConfirmedPhone={ownerDetails.phoneVerified}
                isConfirmedFacebook={ownerDetails.facebookVerified}
                connectedPageId={ownerDetails.facebookPageId}
              />
            </VerifiedInfoSectionWrapper>
          </MiddleSectionWrapper>
          <BottomView>
            <ContactOwnerButton data-testid={'contact-owner-button'} onClick={() => contactOwner(userId, objectId)} />
          </BottomView>
        </BorderContainer>
      )}
    </>
  );
};
