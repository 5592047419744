import { useRef, useState } from 'react';

import { CachedDataStatus, CacheState } from './cachedDataTypes';

export const useCacheState = <T>(): CacheState<T> => {
  const [data, setData] = useState<T | null>(null);
  const [status, setStatus] = useState<CachedDataStatus>('notFetched');
  const requestInProgress = useRef<boolean>(false);
  return { data, status, setData, setStatus, requestInProgress };
};
