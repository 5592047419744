import { useTranslation } from 'react-i18next';

import ContactButton from 'components/buttons/ContactButton';

import { TrackedItemComponentsProps } from './trackedItemUtils';

export const ContactUserButton = ({ isBuyer, onClick, onContactPress }: TrackedItemComponentsProps) => {
  const { t } = useTranslation('myProfile');

  const handleOnClick = () => {
    onClick?.();
    onContactPress?.();
  };

  return (
    <ContactButton onClick={handleOnClick} data-testid={'contact-owner'}>
      {isBuyer ? t('tracked-item.contact-seller') : t('tracked-item.contact-buyer')}
    </ContactButton>
  );
};
