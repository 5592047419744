import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import CartWoman from 'assets/svg/cart_woman.svg';
import WhiteButton from 'components/buttons/WhiteButton';
import { H3 } from 'components/typography/Headers';
import Spinner from 'components/ui-elements/Spinner';
import { useItemPopup } from 'features/add-item/form/useItemPopup';
import { CampaignDto } from 'services/campaign/campaignService.dto';

const PostSomethingButton = styled(WhiteButton)`
  max-width: 240px;
  margin: 10px 0;
  background-color: ${theme.color.red};
  color: ${theme.color.white};

  ${theme.mq.phone} {
    background-color: white;
    color: ${theme.color.red};
    border: 2px solid ${theme.color.red};
    max-width: 100%;
    margin: 0;
  }
`;

const PostSomethingTextContainer = styled.div`
  margin-left: 24px;
  
  ${theme.mq.phone} {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0;
`;

const PostSomethingText = styled(H3)`
  margin-bottom: 15px;

  ${theme.mq.desktop} {
    font-size: 34px;
    line-height: 44px;
  }

  ${theme.mq.phone} {
    text-align: center;
    margin-bottom: 24px;
  }
`;

const PostSomethingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${theme.mq.phone} {
    flex-direction: column;
    margin: 0 auto;
  }
`;

const BorderContainer = styled.div`
  display: flex;
  flex: 1;
  border: 1px solid ${theme.color.darkGray};
  border-radius: 5px;
  padding: 36px;

  ${theme.mq.desktop} {
    padding: 24px;
  }
  ${theme.mq.phone} {
    padding: 12px;
  }
`;

const CartWomanImg = styled.img`
  width: 100%;
  max-height: 310px;

  ${theme.mq.desktop} {
    max-height: 240px;
  }
`;

interface Props {
  campaign?: CampaignDto;
}

export const PostSomething = ({ campaign }: Props) => {
  const { t } = useTranslation('common');
  const { showAddItemPopup } = useItemPopup();

  return (
    <BorderContainer>
      {!campaign && <Spinner />}
      {!!campaign && (
        <PostSomethingContainer data-testid={'post-something-container'}>
          <CartWomanImg src={CartWoman} alt='woman with cart notification' />
          <PostSomethingTextContainer>
            <PostSomethingText>{t('post-something-text')}</PostSomethingText>
            <PostSomethingButton onClick={() => showAddItemPopup(campaign)} data-testid={'post-something-button'}>
              {t('post-something-button')}
            </PostSomethingButton>
          </PostSomethingTextContainer>
        </PostSomethingContainer>
      )}
    </BorderContainer>
  );
};
