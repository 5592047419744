import { useTranslation } from 'react-i18next';

import FormStepWrapper from 'components/form/FormStepWrapper';
import { useAddItem } from 'features/add-item/context/AddItemProvider';
import ItemSummaryForm from 'features/add-item/form/components/ItemSummaryForm';

const Confirm = () => {
  const { t } = useTranslation('addItem');
  const { isFormValid, save } = useAddItem();
  return (
    <FormStepWrapper header={t('confirm.header')} isValid={isFormValid} onNextPress={save}>
      <ItemSummaryForm />
    </FormStepWrapper>
  );
};

export default Confirm;
