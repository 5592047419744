import { chain } from 'lodash';

import { ImageDto } from 'services/image/useUploadImages';

export const recognizeTitle = (images: ImageDto[]) => {
  const safeAndUploaded = images.filter(image => image.safe && !image.isUploading);

  const groupedResults = chain(safeAndUploaded)
    .groupBy(row => row.topLabel)
    .map((value, key) => ({
      title: key,
      count: value.length,
      maxAccuracy: value
        .map(result => result.topLabelConfidence)
        .reduce((prev, curr) => Math.max(prev || 0, curr || 0)),
    }))
    .orderBy(['count', 'maxAccuracy'], ['desc', 'desc'])
    .value();

  return groupedResults[0]?.title;
};
